import { TOUR_POINT_STORAGE_KEY } from '../config';

function getStorageKey(userId: string): string {
  return `${TOUR_POINT_STORAGE_KEY}_${userId}`;
}

interface ISavedTourAppVersion {
  date: string;
}

function getAllSavedTourData(
  userId: string
): Record<string, ISavedTourAppVersion> {
  const storageKey = getStorageKey(userId);
  const savedTour = localStorage.getItem(storageKey);

  if (savedTour) {
    return JSON.parse(savedTour);
  }

  return {};
}

export function getSavedTour(
  userId: string,
  tourType: string
): ISavedTourAppVersion | null {
  const savedTourData = getAllSavedTourData(userId);

  const tourData = savedTourData[tourType];
  if (tourData) {
    return tourData;
  }

  return null;
}

export function updateSavedTour(userId: string, tourType: string): boolean {
  const savedTourData = getAllSavedTourData(userId);

  try {
    const newTourData: Record<string, ISavedTourAppVersion> = {
      ...savedTourData,
    };

    newTourData[tourType] = {
      date: new Date().toISOString(),
    };

    localStorage.setItem(getStorageKey(userId), JSON.stringify(newTourData));

    return true;
  } catch (_) {
    return false;
  }
}
