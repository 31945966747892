import { Navigate } from 'react-router-dom';
import { checkPathIsValid, stripURLOrigin } from '@cbe/ui';

import { AuthenticationError, useAuthentication } from '.';

export const AuthenticationWrapper = ({
  children,
}: {
  children: React.ReactElement;
}): JSX.Element => {
  const { isAuthenticated } = useAuthentication();

  if (isAuthenticated) {
    return children;
  }

  const location = checkPathIsValid(
    stripURLOrigin(`${window.location.origin}/`, window.location.href)
  );
  return (
    <Navigate
      to={
        typeof location === 'string'
          ? `/?error=${
              AuthenticationError.unauthenticated
            }&location=${encodeURIComponent(location)}`
          : `/?error=${AuthenticationError.unauthenticated}`
      }
      replace
    />
  );
};
