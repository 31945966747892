import { IconButton, IconButtonProps } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

/**
 * Start whats new tour button can be used in different module pages
 * to begin the whats new tour from any module, which reduces the
 * tour down the latest changes.
 */
export const StartWhatsNewTourButton = ({
  color = 'default',
  id = 'module-whats-new-tour-button',
  ...rest
}: IconButtonProps): JSX.Element => {
  return (
    <IconButton color={color} id={id} size="large" {...rest}>
      <NewReleasesIcon />
    </IconButton>
  );
};
