import type { IRawTourData, IStepsData } from '../types';
import TourPointContent from '../components/TourPointContent';

export function formatTourStepData(
  step: IRawTourData,
  checkConditions: (condition: string[]) => boolean
): IStepsData | null {
  const {
    condition,
    content,
    options,
    releaseDate,

    selector,
    highlightedSelectors = [],
    mutationObservables = [],
    resizeObservables = [],

    bypassElem,
    padding,
    position,
  } = step;

  // Add step if no conditions or if the checkConditions function returns as true
  if (!condition || checkConditions(condition) === true) {
    return {
      content: (props) => {
        return (
          <TourPointContent
            {...props}
            data={content}
            checkConditions={checkConditions}
          />
        );
      },
      stepOptions: {
        actionOnChange: options?.actionOnChange,
        title: options?.title || '',
        releaseDate,
      },

      selector,
      resizeObservables,
      highlightedSelectors,
      mutationObservables,

      bypassElem,
      padding,
      position,
    };
  }

  return null;
}
