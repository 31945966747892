import loadable from '@loadable/component';
import type { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

export const FulfilmentRoutes: IRoute = {
  id: 'Fulfilment',
  guid: ModuleMap.Fulfilment.View,

  path: '/Fulfilment',
  redirectTo: '/Fulfilment/Orders',

  icon: <TakeoutDiningIcon />,

  modules: [
    {
      id: 'FulfilmentOrders',
      guid: ModuleMap.Fulfilment.Orders,

      path: '/Fulfilment/Orders',
      Component: loadable(() => import('./modules/Orders'), loadableConfig),
      children: [
        {
          index: true,
          Component: loadable(
            () => import('./modules/Orders/modules/Orders'),
            loadableConfig
          ),
        },
        {
          path: ':id',
          Component: loadable(
            () => import('./modules/Orders/modules/OrderDetails'),
            loadableConfig
          ),
        },
      ],

      icon: <AssignmentTurnedInIcon />,
      sidebarPath: '/Fulfilment/Orders',
    },
    {
      id: 'FulfilmentDeliveries',
      guid: ModuleMap.Fulfilment.Deliveries,

      path: '/Fulfilment/Deliveries',
      Component: loadable(() => import('./modules/Deliveries'), loadableConfig),
      children: [
        {
          index: true,
          Component: loadable(
            () => import('./modules/Deliveries/modules/Deliveries'),
            loadableConfig
          ),
        },
        {
          path: ':id',
          Component: loadable(
            () => import('./modules/Deliveries/modules/DeliveryDetails'),
            loadableConfig
          ),
        },
      ],

      icon: <LocalShippingIcon />,
      sidebarPath: '/Fulfilment/Deliveries',
    },
    {
      id: 'FulfilmentReturns',
      guid: ModuleMap.Fulfilment.Returns,

      path: '/Fulfilment/Returns',
      Component: loadable(() => import('./modules/Returns'), loadableConfig),
      children: [
        {
          index: true,
          Component: loadable(
            () => import('./modules/Returns/modules/Returns'),
            loadableConfig
          ),
        },
        {
          path: ':id',
          Component: loadable(
            () => import('./modules/Returns/modules/ReturnsDetails'),
            loadableConfig
          ),
        },
      ],

      icon: <RotateLeftIcon />,
      sidebarPath: '/Fulfilment/Returns',
    },
  ],
};
