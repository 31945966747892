import React, { memo } from 'react';
import styled from '@emotion/styled';
import { CssBaseline } from '@mui/material';

import { CBECloudLogo } from '@cbe/ui';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 60% 1fr;
  grid-template-rows: 1fr;
  align-items: stretch;
  justify-items: stretch;
  ${(props) => props.theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
  }

  div.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.66);
    z-index: 1;

    div.logo-container {
      margin-bottom: ${(props) => props.theme.spacing(5)};
    }
  }

  div.background-image {
    background-image: url(/static/images/login/Women_Shopping.jpg);
    background-size: cover;
    background-position: center;
    ${(props) => props.theme.breakpoints.down('lg')} {
      display: none;
    }

    > div.background-image-gradient {
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #00000088 25%, #ffffff44 95%);
    }
  }
`;

export default memo(function _LoginLayout({
  children,
}: {
  children: React.ReactElement;
}) {
  return (
    <React.Fragment>
      <CssBaseline />

      <Container className="page-wrapper">
        <div className="auth-container">
          <div className="logo-container">
            <CBECloudLogo size="xl" lightAltColor />
          </div>

          {children}
        </div>

        <div className="background-image">
          <div className="background-image-gradient">&nbsp;</div>
        </div>
      </Container>
    </React.Fragment>
  );
});
