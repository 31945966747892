import type { ISiteData } from './types';

/**
 * Function to get the unique values from the users stores.
 *
 * @example
 * ```ts
 * formatUserData(
 *   'name',
 *   [{ name: 'store1' }, { name: 'store2' }]
 * ) => ['store1', 'store2']
 * ```
 */
export function flattenStores<Key extends keyof ISiteData>(
  stores: ISiteData[],
  key: Key
): ISiteData[Key][] {
  const _data: ISiteData[Key][] = [];

  for (const store of stores) {
    if (store[key] && !_data.includes(store[key])) {
      _data.push(store[key]);
    }
  }

  return _data.sort();
}

/**
 * Function to return the selected values in the required stores format.
 *
 * @example
 * ```ts
 * unFlattenStores(
 *   [{ name: 'store1' }, { name: 'store2' }],
 *   'name',
 *   ['store1']
 * ) => [{ name: 'store1' }];
 * ```
 */
export function unFlattenStores<Key extends keyof ISiteData>(
  stores: ISiteData[],
  key: Key,
  value: ISiteData[Key][]
): Record<string, ISiteData[Key]>[] {
  const _data: Record<string, ISiteData[Key]>[] = [];

  for (const store of stores) {
    if (store[key] && value.includes(store[key])) {
      _data.push({
        [key]: store[key],
      });
    }
  }

  return _data;
}
