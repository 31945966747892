import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import DoneAllIcon from '@mui/icons-material/DoneAll';

export const CommunicationCentreRoutes: IRoute = {
  id: 'CommunicationCentre',
  guid: ModuleMap.CommunicationCentre.View,

  path: '/CommunicationCentre',
  Component: loadable(() => import('./'), loadableConfig),

  icon: <DoneAllIcon />,
  sidebarPath: '/CommunicationCentre',

  modules: [
    {
      id: 'CommunicationCentreEmployees',
      guid: ModuleMap.Employees.View,

      path: '/CommunicationCentre/Employees',
      Component: loadable(() => import('./modules/Employees'), loadableConfig),
    },
    {
      id: 'CommunicationCentreProducts',
      guid: ModuleMap.Products.View,

      path: '/CommunicationCentre/Products',
      Component: loadable(() => import('./modules/Products'), loadableConfig),
    },
    {
      id: 'CommunicationCentreWetstock',
      guid: ModuleMap.Wetstock.PriceViewer.View,

      path: '/CommunicationCentre/Wetstock',
      Component: loadable(() => import('./modules/Wetstock'), loadableConfig),
    },
    {
      id: 'CommunicationCentrePromotions',
      guid: ModuleMap.Promotions.View,

      path: '/CommunicationCentre/Promotions',
      Component: loadable(() => import('./modules/Promotions'), loadableConfig),
    },
    {
      id: 'CommunicationCentreContentManager',
      guid: ModuleMap.ContentManager.View,

      path: '/CommunicationCentre/ContentManager/:id?',
      Component: loadable(
        () => import('./modules/ContentManager/ContentManager'),
        loadableConfig
      ),
    },
    {
      id: 'CommunicationCentreCustomerAccounts',
      guid: ModuleMap.CustomerAccounts.View,

      path: '/CommunicationCentre/CustomerAccounts',
      Component: loadable(
        () => import('./modules/CustomerAccounts'),
        loadableConfig
      ),
    },

    {
      // Batching overwrite to ensue old link redirects.
      id: 'Batching',
      guid: undefined,

      path: '/Batching',
      redirectTo: '/CommunicationCentre',
    },
    {
      // Wetstock prices overwrite to ensue old link redirects.
      id: 'WetstockPrices',
      guid: undefined,

      path: '/CommunicationCentre/Prices',
      redirectTo: '/CommunicationCentre/Wetstock',
    },
  ],
};
