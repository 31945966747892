import { useEffect, useState } from 'react';
import { useAbortSignal, useDebounce, useIsOpen } from '@cbe/ui';
import { getData } from 'app/libs/axios';
import { useAuthentication } from '../Authentication';
import { useAuthorisation } from '../Authorisation';

export interface IDetail {
  id: number;
  date: string;
  content: string;
  images: string[];
}
export interface IReleaseNotes {
  name: string;
  version: string;
  date: string;
  details: IDetail[];
}

function readLastVersion(userId: string): IReleaseNotes['version'] | null {
  const data = localStorage.getItem(`release_notes_${userId}`);
  if (data) {
    return JSON.parse(data);
  }
  return null;
}
function writeLastVersion(userId: string, data: IReleaseNotes['version']) {
  localStorage.setItem(`release_notes_${userId}`, JSON.stringify(data));
}

export const useReleaseNotesData = (): {
  data: IReleaseNotes | null;
  releaseNotesOpen: boolean;
  openReleaseNotes: () => void;
  closeReleaseNotes: () => void;
} => {
  const { createController, abort } = useAbortSignal();
  const debounce = useDebounce(500);
  const { account } = useAuthentication();
  const { user } = useAuthorisation();

  const [releaseNotesOpen, openReleaseNotes, closeReleaseNotes] = useIsOpen();
  const [data, setData] = useState<IReleaseNotes | null>(null);

  const isAuthorised = Boolean(user);
  const userId = account?.id;

  useEffect(() => {
    async function loadData() {
      const apiResponse = await getData<IReleaseNotes | null>({
        api: `${window.location.origin}/release-notes/Release-Notes.json`,
        config: {
          signal: createController().current.signal,
        },
        defaultResponse: null,
      });

      if (apiResponse?.data && 'version' in apiResponse.data) {
        setData(apiResponse.data);

        const lastVersion = readLastVersion(userId!);
        if (lastVersion !== apiResponse.data.version) {
          writeLastVersion(userId!, apiResponse.data.version);
          openReleaseNotes();
        }
      }
    }

    // Disabling this for now until we have some release notes to show
    // eslint-disable-next-line no-constant-condition
    if (isAuthorised && userId && false) {
      debounce(loadData);
    }

    return () => {
      abort();
    };
  }, [
    abort,
    createController,
    debounce,
    openReleaseNotes,
    isAuthorised,
    userId,
  ]);

  return {
    data,
    releaseNotesOpen,
    openReleaseNotes,
    closeReleaseNotes,
  };
};
