/**
 * COUNTRY_CODES should be used to map names to the necessary culture info.
 */
export const COUNTRY_CODES = [
  {
    name: 'Ireland',
    isoCountryCode: 'IE',
    language: 'English',
    isoLanguageCode: 'en-IE',
  },
  {
    name: 'United Kingdom',
    isoCountryCode: 'GB',
    language: 'English',
    isoLanguageCode: 'en-GB',
  },
];
