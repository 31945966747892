import { useContext } from 'react';
import AuthenticationContext from './AuthenticationContext';
import type { IAuthenticationContext } from './types';

const useAuthentication = (): IAuthenticationContext => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error(
      'useAuthentication should be used inside an AuthenticationProvider'
    );
  }
  return context;
};

export default useAuthentication;
