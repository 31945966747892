import type { TourProps } from '@reactour/tour';
import type { CustomTheme } from '@cbe/ui';

export function getTourStyles(theme: CustomTheme): TourProps['styles'] {
  // Main Tour Styles
  // https://github.com/elrumordelaluz/reactour/tree/master/packages/tour#styles-stylesobj--popoverstylesobj--maskstylesobj-1
  //
  return {
    controls: (base) => {
      return {
        ...base,
        justifyContent: 'space-around',
        marginTop: 0,
      };
    },
    dot: (base, options) => {
      return {
        ...base,
        background:
          (options as any).current === true
            ? theme.palette.primary.main
            : base.color,
      };
    },

    // Mask Styles
    // https://github.com/elrumordelaluz/reactour/tree/master/packages/mask#styles-stylesobj
    //
    maskArea: (base) => {
      return {
        ...base,
        // 'rx' is honestly the worst variable name I've ever seen, and
        // its lack of actual documentation annoys but it is what
        // sets the border radius around the highlighted area.
        rx: 8,
      };
    },

    // Popover Styles
    // https://github.com/elrumordelaluz/reactour/tree/master/packages/popover#styles-stylesobj
    //
    popover: (base) => {
      return {
        ...base,
        width: '420px',
        maxWidth: '94%',

        padding: 0,

        boxShadow: theme.shadows[15],
        borderRadius: '8px',
        overflow: 'hidden',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
      };
    },
  };
}
