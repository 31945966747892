import {
  AccountInfo,
  AuthenticationResult,
  AuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import queryString from 'query-string';

import {
  AuthenticationError,
  IAccount,
  IAuthentication,
} from 'app/features/Authentication';

import {
  LOGIN_SCOPES,
  MSAL_CONFIG,
  POPUP_POSITION,
  TOKEN_SCOPES,
} from './config';

class MSALAuthentication implements IAuthentication {
  private _client: PublicClientApplication;
  private _isIE = false;
  private _isInteracting = false;

  public hasPopupHash = false;

  constructor() {
    this._client = new PublicClientApplication(MSAL_CONFIG);

    this._isIE = this.checkIfBrowserIsIE();
    this.hasPopupHash = !this._isIE && this.checkIfPopupHashExists();

    this.initialise();
  }

  // Methods to handle the users auth state and account object types.
  //

  private setLoggedInAccount(account: AccountInfo | null): boolean {
    if (account?.localAccountId) {
      this._client.setActiveAccount(account);
      return true;
    }

    this._client.setActiveAccount(null);
    return false;
  }

  private getAccountObject(account: AccountInfo): IAccount {
    return {
      id: account.localAccountId,
      name: `${account.name}`,
      username: account.username,
    };
  }

  // Authentication initialisation
  //

  public async initialise(): Promise<void> {
    await this._client.initialize();

    if (this._isIE) {
      const redirectResponse = await this._client.handleRedirectPromise();

      if (redirectResponse?.account?.localAccountId) {
        this.setLoggedInAccount(redirectResponse.account);
      }
    } else {
      // Due to us using logoutRedirect we need to use handleRedirectPromise.
      if (window.sessionStorage.getItem('logout_inprogress') === 'true') {
        await this._client.handleRedirectPromise();

        window.sessionStorage.removeItem('logout_inprogress');
      }
    }
  }

  private checkIfBrowserIsIE(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ') > -1;
    const msie11 = ua.indexOf('Trident/') > -1;

    return msie || msie11;
  }

  private checkIfPopupHashExists(): boolean {
    const windowHash = window.location.hash;
    const hash = queryString.parse(windowHash);

    if (
      hash['code'] &&
      hash['client_info'] &&
      hash['state'] &&
      hash['session_state']
    ) {
      return true;
    }

    return false;
  }

  // Public methods to get the private account data
  //

  public getAllAccounts(): IAccount[] {
    return this._client.getAllAccounts().map(this.getAccountObject);
  }

  public getAccount(): IAccount | null {
    const activeAccount = this._client.getActiveAccount();

    if (activeAccount) {
      return this.getAccountObject(activeAccount);
    }
    return null;
  }

  // User interactions
  //

  public async login(): Promise<IAccount | AuthenticationError | void> {
    if (this._isIE) {
      return this._client.loginRedirect({
        scopes: LOGIN_SCOPES,
        prompt: 'login',
      });
    }

    /**
     * Check if a user is currently interacting with auth,
     * if not set interacting state to stop from multiple
     * auth calls at once.
     */
    if (this._isInteracting === true) {
      return;
    }
    this._isInteracting = true;

    try {
      const response = await this._client.loginPopup({
        scopes: LOGIN_SCOPES,
        prompt: 'select_account',
        popupWindowAttributes: POPUP_POSITION,
      });

      const validLogin = this.setLoggedInAccount(response?.account);
      if (!validLogin) {
        throw new Error('Invalid user account');
      }

      this._isInteracting = false;
      return this.getAccountObject(response.account!);
    } catch (error) {
      this._isInteracting = false;

      if (
        `${(error as AuthError)?.errorMessage}`.indexOf(
          'User cancelled the flow'
        ) > -1
      ) {
        return AuthenticationError.cancelled;
      }

      return AuthenticationError.common;
    }
  }

  public async logout(): Promise<void> {
    // Due to us using logoutRedirect we need to inform the
    // initialise method to use handleRedirectPromise.
    window.sessionStorage.setItem('logout_inprogress', 'true');

    const activeAccount = this._client.getActiveAccount();
    return await this._client.logoutRedirect({ account: activeAccount });
  }

  // Cached browser accounts data
  //

  public selectAccount(account: IAccount): AuthenticationError | true {
    const selected = this._client
      .getAllAccounts()
      .find(({ localAccountId }) => localAccountId === account.id);

    const validLogin = this.setLoggedInAccount(selected!);
    if (!validLogin) {
      return AuthenticationError.common;
    }

    return validLogin;
  }

  public async removeAccount(account: IAccount): Promise<void> {
    try {
      const selected = this._client
        .getAllAccounts()
        .find(({ localAccountId }) => localAccountId === account.id);

      return await this._client.logoutPopup({
        account: selected,
        popupWindowAttributes: POPUP_POSITION,
      });
    } catch (_) {
      console.log('Error removing account.');
    }
  }

  // Bearer token acquisition
  //

  /**
   * If an error occurs while trying to do a silent token acquisition then
   * retry with a popup token instead.
   */
  private async handleAcquireTokenError(): Promise<AuthenticationResult | void> {
    /**
     * Check if a user is currently interacting with auth, if not set
     * interacting state to stop from multiple auth calls at once.
     */
    if (this._isInteracting) {
      return;
    }
    this._isInteracting = true;

    try {
      const response = await this._client.acquireTokenPopup({
        scopes: TOKEN_SCOPES,
        popupWindowAttributes: POPUP_POSITION,
      });
      if (!response) {
        throw new Error('No response from login');
      }

      this.setLoggedInAccount(response.account);

      this._isInteracting = false;
      return response;
    } catch (_) {
      console.warn('Popup token acquisition fail.');

      this._isInteracting = false;
      return;
    }
  }

  /**
   * The acquireToken function is used to return an access token for use
   * when a user needs to do an authenticated data call.
   */
  private async acquireToken(): Promise<AuthenticationResult | void> {
    const activeAccount = this._client.getActiveAccount();
    if (!activeAccount) {
      return;
    }

    try {
      const response = await this._client.acquireTokenSilent({
        scopes: TOKEN_SCOPES,
        account: activeAccount,
      });

      if (!activeAccount.localAccountId) {
        if (
          !this.setLoggedInAccount(
            this._client.getAccountByLocalId(response.uniqueId)
          )
        ) {
          throw new Error('Account info not found');
        }
      }

      return response;
    } catch (_) {
      console.warn('Silent token acquisition fail. Acquire using Popup');

      return this.handleAcquireTokenError();
    }
  }

  public async getToken(): Promise<string | null> {
    const token = await this.acquireToken();
    if (token) {
      return token.accessToken;
    }

    return null;
  }
}

export default MSALAuthentication;
