import i18next, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { lowercase, uppercase, datefn } from './formatFunctions';

const config: InitOptions = {
  fallbackLng: 'en',
  // debug: __DEV__,

  ns: ['common'],
  defaultNS: 'common',

  interpolation: {
    escapeValue: false,
  },
};

i18next.use(Backend).use(LanguageDetector).use(initReactI18next).init(config);

// Add format functions to i18next
//

i18next.services.formatter?.add('lowercase', lowercase);
i18next.services.formatter?.add('uppercase', uppercase);
i18next.services.formatter?.add('datefn', datefn);

export default i18next;
