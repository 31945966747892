import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ListIcon from '@mui/icons-material/List';

export const CustomerAccountsRoutes: IRoute = {
  id: 'CustomerAccounts',
  guid: ModuleMap.CustomerAccounts.View,

  path: '/CustomerAccounts',
  redirectTo: '/CustomerAccounts/CustomerAccountsHub',

  icon: <GroupIcon />,

  modules: [
    {
      id: 'CustomerAccountsHub',
      guid: ModuleMap.CustomerAccounts.Hub,

      path: '/CustomerAccounts/CustomerAccountsHub',
      Component: loadable(
        () => import('./modules/Hub/HubOutlet'),
        loadableConfig
      ),
      icon: <DashboardIcon />,
      sidebarPath: '/CustomerAccounts/CustomerAccountsHub',

      children: [
        {
          id: 'HubIndex',

          index: true,
          Component: loadable(
            () => import('./modules/Hub/components/CustomerAccountsHubPage'),
            loadableConfig
          ),
        },
      ],
    },
    {
      id: 'Customers',
      guid: ModuleMap.CustomerAccounts.Customers,

      path: '/CustomerAccounts/CustomerList',
      Component: loadable(
        () => import('./modules/Customers/CustomerOutlet'),
        loadableConfig
      ),

      icon: <ListIcon />,
      sidebarPath: '/CustomerAccounts/CustomerList',

      children: [
        {
          id: 'CustomerList',

          index: true,
          Component: loadable(
            () => import('./modules/Customers/components/CustomerListPage'),
            loadableConfig
          ),
        },

        {
          id: 'CustomerView',

          path: '/CustomerAccounts/CustomerList/CustomerView/:id?',
          Component: loadable(
            () =>
              import(
                './modules/Customers/components/CustomerView/CustomerViewPage'
              ),
            loadableConfig
          ),
        },
      ],
    },

    {
      id: 'StatementOfAccounts',
      guid: ModuleMap.CustomerAccounts.StatementOfAccounts,

      path: '/CustomerAccounts/StatementOfAccounts',
      Component: loadable(
        () => import('./modules/Statements/StatementsOutlet'),
        loadableConfig
      ),
      icon: <SummarizeIcon />,
      sidebarPath: '/CustomerAccounts/StatementOfAccounts',

      children: [
        {
          id: 'StatementOfAccountsIndex',

          index: true,
          Component: loadable(
            () =>
              import('./modules/Statements/components/StatementOfAccountsPage'),
            loadableConfig
          ),
        },
      ],
    },
  ],
};
