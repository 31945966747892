import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import LoyaltyIcon from '@mui/icons-material/Loyalty';

export const PromotionsRoutes: IRoute = {
  id: 'Promotions',
  guid: ModuleMap.Promotions.View,

  path: '/Promotions',
  Component: loadable(() => import('./'), loadableConfig),

  icon: <LoyaltyIcon />,
  sidebarPath: '/Promotions',
};
