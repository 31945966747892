import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { IconButton, Tooltip as MuiTooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SECONDS_TO_SHOW_NOTIFICATION } from 'app/features/ModuleTour';
import type { CustomTheme } from '@cbe/ui';
import type { TooltipProps } from '@mui/material/Tooltip';

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }: { theme?: CustomTheme }) => ({
  '.MuiTooltip-tooltip': {
    backgroundColor: theme?.custom.highlight.background,
    fontSize: theme?.typography.pxToRem(16),
  },
  '.tooltip-title-content': {
    color: theme?.palette.text.primary,
    fontSize: theme?.typography.pxToRem(16),
    padding: '0 8px 0 6px',
    display: 'flex',
    alignItems: 'center',
  },
  '.MuiButtonBase-root': {
    marginRight: '12px',
  },
  '.MuiTooltip-arrow': {
    color: theme?.custom.highlight.background,
  },
}));

interface ITourNotification {
  text?: string;
  isEnabled: boolean;
  children: React.ReactNode;
}

/**
 * Tooltip to emphasise the existence of the app tour button.
 */
export const TourNotification = ({
  text,
  isEnabled = false,
  children,
}: ITourNotification): JSX.Element => {
  const { t, ready } = useTranslation(['common'], { useSuspense: false });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    let timeout: null | NodeJS.Timeout = null;

    if (isEnabled === true) {
      setOpen(true);

      const millisecondsToShow = SECONDS_TO_SHOW_NOTIFICATION * 1000;
      timeout = setTimeout(() => {
        setOpen(false);

        timeout = null;
      }, millisecondsToShow);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isEnabled]);

  return (
    <Tooltip
      open={open}
      enterDelay={250}
      leaveDelay={100}
      arrow
      id="start-tour-tooltip"
      placement="left"
      title={
        <span className="tooltip-title-content">
          <IconButton
            color="inherit"
            onClick={() => setOpen(false)}
            size="small"
          >
            <CloseIcon />
          </IconButton>

          {typeof text === 'string'
            ? text
            : ready
            ? t('common:tour.tooltip')
            : ''}
        </span>
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};
