import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  List as MuiList,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
} from '@mui/material';
import { LinkBehavior } from '@cbe/ui';

const List = styled(MuiList)`
  height: 100%;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
` as unknown as typeof MuiList;
const ListItem = styled(MuiListItem)`
  height: 32px;
  padding: 0 6px;
  margin-right: 12px;
  white-space: nowrap;
` as unknown as typeof MuiListItem;
const ListItemText = styled(MuiListItemText)`
  color: ${({ theme }) => {
    const { footer, text } = theme.navigation;
    return footer?.color || text.primary;
  }};
`;

export const MainLinks = (): JSX.Element => {
  const { t } = useTranslation(['common']);

  return (
    <List component="div">
      <ListItem component="a" href="#">
        <ListItemText primary={t('common:footer.links.support')} />
      </ListItem>

      <ListItem component="a" href="#">
        <ListItemText primary={t('common:footer.links.help-center')} />
      </ListItem>

      <ListItem component="a" href="#">
        <ListItemText primary={t('common:footer.links.privacy')} />
      </ListItem>

      <ListItem component={LinkBehavior} href="/TermsOfContract">
        <ListItemText primary={t('common:footer.links.terms-of-contract')} />
      </ListItem>
    </List>
  );
};
