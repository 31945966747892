import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import BusinessIcon from '@mui/icons-material/Business';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import UploadFileIcon from '@mui/icons-material/UploadFile';

export const WholesalersRoutes: IRoute = {
  id: 'Wholesalers',
  guid: ModuleMap.Wholesalers.View,

  path: '/Wholesalers',
  redirectTo: '/Wholesalers/Imports',

  icon: <BusinessIcon />,

  modules: [
    {
      id: 'WholesalerImports',
      guid: ModuleMap.Wholesalers.Imports,

      path: '/Wholesalers/Imports',
      Component: loadable(
        () => import('./modules/WholesalerImports'),
        loadableConfig
      ),
      children: [
        {
          index: true,
          Component: loadable(
            () => import('./modules/WholesalerImports/modules/Overview'),
            loadableConfig
          ),
        },
        {
          path: 'Catalogue',
          Component: loadable(
            () => import('./modules/WholesalerImports/modules/Catalogue'),
            loadableConfig
          ),
        },
        {
          path: 'Leaflets',
          Component: loadable(
            () => import('./modules/WholesalerImports/modules/Leaflets'),
            loadableConfig
          ),
        },
        {
          path: 'Deliveries',
          Component: loadable(
            () => import('./modules/WholesalerImports/modules/Deliveries'),
            loadableConfig
          ),
        },
        {
          path: 'FullCatalogue',
          Component: loadable(
            () => import('./modules/WholesalerImports/modules/FullCatalogue'),
            loadableConfig
          ),
        },
      ],

      icon: <CloudDownloadIcon />,
      sidebarPath: '/Wholesalers/Imports',
    },

    {
      id: 'WholesalerExports',
      guid: ModuleMap.Wholesalers.Exports,

      path: '/Wholesalers/Exports',
      Component: loadable(
        () => import('./modules/WholesalerExports'),
        loadableConfig
      ),

      icon: <UploadFileIcon />,
      sidebarPath: '/Wholesalers/Exports',
    },
  ],
};
