export enum AuthenticationError {
  cancelled = 'cancelled',
  common = 'common',
  unauthenticated = 'unauthenticated',
  unauthorised = 'unauthorised',
}

interface IAccount {
  id: string;
  name: string;
  username: string;
}

interface IAuthentication {
  hasPopupHash: boolean;

  initialise(): Promise<void>;

  getAllAccounts(): IAccount[];
  getAccount(): IAccount | null;

  login(): Promise<IAccount | AuthenticationError | void>;
  logout(): Promise<void>;

  selectAccount(account: IAccount): AuthenticationError | boolean;
  removeAccount(account: IAccount): Promise<void>;

  getToken(): Promise<string | null>;
}

interface IAuthenticationContext {
  isAuthenticated: boolean;

  account: IAccount | null;
  error: AuthenticationError | null;
  signIn(): Promise<IAccount | undefined>;
  signOut(): Promise<void>;

  savedAccounts: IAccount[];
  selectAccount(account: IAccount): void;
  removeAccount(account: IAccount): Promise<void>;

  getToken: IAuthentication['getToken'];
}

export type { IAccount, IAuthentication, IAuthenticationContext };
