import {
  Configuration,
  BrowserCacheLocation,
  PopupWindowAttributes,
  LogLevel,
} from '@azure/msal-browser';
import { APP_VERSION } from 'app/constants';
import { appInsights } from 'app/features/Analytics/AppInsightsService';

const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const AUTH_AUTHORITY_URI = process.env.REACT_APP_AUTH_AUTHORITY_URI;
const AUTH_TENANT_ID = process.env.REACT_APP_AUTH_TENANT_ID;
const AUTH_TOKENS_URI = process.env.REACT_APP_AUTH_TOKENS_URI;

class AuthorisationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AuthorisationError';
  }
}

export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: `${AUTH_CLIENT_ID}`,
    authority: `${AUTH_AUTHORITY_URI}/${AUTH_TENANT_ID}`,
    redirectUri: `${window.location.origin}/Redirect`,
    postLogoutRedirectUri: `${window.location.origin}`,
  },
  cache: {
    // This configures where your cache will be stored
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (!containsPii) {
          appInsights.trackException({
            exception: new AuthorisationError(message),
            properties: {
              appName: 'CBE Cloud',
              appVersion: APP_VERSION!,
              message,
            },
          });
        }
      },
      logLevel: LogLevel.Warning,
    },
  },
};

export const POPUP_POSITION: PopupWindowAttributes = {
  popupPosition: {
    left: 0,
    top: 0,
  },
  popupSize: {
    width: Math.min(window.innerWidth, 500),
    height: Math.min(window.innerHeight, 550),
  },
};

/**
 * MSAL request scopes
 */

export const LOGIN_SCOPES = ['openid', 'offline_access'];
export const TOKEN_SCOPES = ['openid', `${AUTH_TOKENS_URI}/myaccess`];
