import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap, UserRole } from 'app/constants';

import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import BalanceIcon from '@mui/icons-material/Balance';

export const CashOfficeRoutes: IRoute = {
  id: 'CashOffice',
  guid: ModuleMap.CashOffice.View,

  path: '/CashOffice',
  redirectTo: '/CashOffice/Hub',

  icon: <AccountBalanceWalletIcon />,

  modules: [
    {
      id: 'CashOfficeHub',
      guid: ModuleMap.CashOffice.Hub,

      path: '/CashOffice/Hub',
      Component: loadable(() => import('./modules/Hub'), loadableConfig),

      icon: <DashboardIcon />,
      sidebarPath: '/CashOffice/Hub',
    },

    {
      id: 'CashOfficeDrawers',
      guid: ModuleMap.CashOffice.Drawers,

      path: '/CashOffice/Drawers',
      Component: loadable(() => import('./modules/Drawers'), loadableConfig),

      icon: <PointOfSaleIcon />,
      sidebarPath: '/CashOffice/Drawers',
    },

    {
      id: 'CashOfficeDeclarations',
      guid: ModuleMap.CashOffice.Declarations,

      path: '/CashOffice/Declarations/:id?',
      Component: loadable(
        () => import('./modules/Declarations'),
        loadableConfig
      ),

      icon: <BalanceIcon />,
      sidebarPath: '/CashOffice/Declarations',
      shouldShowInSidebar: (user) => user.stores.length === 1,
    },
    {
      id: 'CashOfficeOfficeBalancing',
      guid: ModuleMap.CashOffice.OfficeBalancing,

      path: '/CashOffice/OfficeBalancing',
      Component: loadable(
        () => import('./modules/OfficeBalancing'),
        loadableConfig
      ),

      icon: <BusinessIcon />,
      sidebarPath: '/CashOffice/OfficeBalancing',
      children: [
        {
          index: true,
          Component: loadable(
            () =>
              import(
                './modules/OfficeBalancing/modules/OfficeBalancingOverview'
              ),
            loadableConfig
          ),
        },
        {
          path: ':id',
          Component: loadable(
            () =>
              import(
                './modules/OfficeBalancing/modules/OfficeBalancingBreakdown'
              ),
            loadableConfig
          ),
        },
      ],
    },
    {
      id: 'CashOfficeBankBalancing',
      guid: ModuleMap.CashOffice.BankBalancing,

      path: '/CashOffice/BankBalancing',
      Component: loadable(
        () => import('./modules/BankBalancing/MultiSiteView'),
        loadableConfig
      ),

      icon: <AccountBalanceIcon />,
      sidebarPath: '/CashOffice/BankBalancing',
      shouldShowInSidebar: (user) => user.roles.includes(UserRole.CBEPreview), // FLAG_ENABLE_EXTRA_CASH_OFFICE_PAGES
    },
    {
      id: 'CashOfficeBankBalancingSingle',
      guid: ModuleMap.CashOffice.BankBalancing,

      path: '/CashOffice/BankBalancing/:id',
      Component: loadable(
        () => import('./modules/BankBalancing/SingleSiteView'),
        loadableConfig
      ),
    },
  ],
};
