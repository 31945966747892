import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import './app/libs/i18next/config';

import { ThemeProvider, LibraryProvider, ErrorBoundary } from '@cbe/ui';
import AuthenticationProvider from './app/features/Authentication/AuthenticationProvider';
import MSALAuthentication from 'app/libs/@azure/MSALAuthentication';
import AuthorisationProvider from './app/features/Authorisation/AuthorisationProvider';
import ModuleTourProvider from 'app/features/ModuleTour/ModuleTourProvider';
import ReleaseNotesProvider from 'app/features/ReleaseNotes/ReleaseNotesProvider';

import { ErrorComponent } from 'app/components/ErrorComponent';
import { AppRoutes } from 'app/routes';
import { APP_INIT_MESSAGE, __PROD__ } from 'app/constants';

if (__PROD__) {
  console.log(APP_INIT_MESSAGE);
}

const authenticationClient = new MSALAuthentication();

const AppRoot = () => {
  const { t, ready } = useTranslation(['common'], { useSuspense: false });

  return (
    <React.StrictMode>
      <HelmetProvider>
        <Helmet
          titleTemplate={`%s ${ready ? t('common:branding.pageTemplate') : ''}`}
          defaultTitle={ready ? t('common:branding.defaultPageTitle') : ''}
          defer={false}
        />

        <LibraryProvider
          t={t('common:lib-translations', { returnObjects: true })}
        >
          <ThemeProvider>
            <ModuleTourProvider>
              <AuthenticationProvider client={authenticationClient}>
                <AuthorisationProvider>
                  <ErrorBoundary Component={ErrorComponent}>
                    <ReleaseNotesProvider>
                      <AppRoutes />
                    </ReleaseNotesProvider>
                  </ErrorBoundary>
                </AuthorisationProvider>
              </AuthenticationProvider>
            </ModuleTourProvider>
          </ThemeProvider>
        </LibraryProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
};

// We need to check if msal is tring to redirect using popup,
// if so we want to stop the app from rendering.
if (authenticationClient.hasPopupHash) {
  const root = document.getElementById('root');
  if (root?.textContent !== undefined) {
    root.textContent = 'Redirecting to application...';
  }

  setTimeout(() => {
    window.close();
  }, 2000); // Wait upto 2 seconds and close window if login redirect fails.
} else {
  ReactDOM.render(<AppRoot />, document.getElementById('root'));
}
