import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import ReceiptIcon from '@mui/icons-material/Receipt';

export const JournalsRoutes: IRoute = {
  id: 'Journals',
  guid: ModuleMap.Journals.View,

  path: '/Journals',
  Component: loadable(() => import('./modules/HubView'), loadableConfig),

  icon: <ReceiptIcon />,
  sidebarPath: '/Journals',

  modules: [
    {
      id: 'JournalsData',
      guid: ModuleMap.Journals.View,

      path: '/Journals/Data',
      Component: loadable(() => import('./modules/DataView'), loadableConfig),
    },
  ],
};
