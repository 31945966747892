// Node runtime environments
//

export const __DEV__ = process.env.NODE_ENV === 'development';
export const __TEST__ = process.env.NODE_ENV === 'test';
export const __PROD__ = process.env.NODE_ENV === 'production';

export const PUBLIC_URL = process.env.PUBLIC_URL;
export const DEPLOYMENT_URL = process.env.REACT_APP_DEPLOYMENT_URL;

/**
 * APIM uri used to generate the string for calls to the APIM service.
 */
export const APIM_URI = process.env.REACT_APP_APIM_URI as string;

/**
 * The app insights key used during config of the Azure App Insights.
 */
export const APP_INSIGHTS_KEY = process.env.REACT_APP_INSIGHTS_KEY;

/**
 * The app version loaded from the package.json file.
 */
export const APP_VERSION = process.env.REACT_APP_VERSION;

/**
 * [ASCII Art Generator](http://patorjk.com/software/taag/#p=display&f=Slant&t=CBE%20Cloud)
 */
export const APP_INIT_MESSAGE = `   __________  ______   ________                __
  / ____/ __ )/ ____/  / ____/ /___  __  ______/ /
 / /   / __  / __/    / /   / / __ \\/ / / / __  /
/ /___/ /_/ / /___   / /___/ / /_/ / /_/ / /_/ /
\\____/_____/_____/   \\____/_/\\____/\\__,_/\\__,_/

App Version: ${APP_VERSION}`;
