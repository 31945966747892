import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoneyIcon from '@mui/icons-material/Money';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export const WetstockRoutes: IRoute = {
  id: 'Wetstock',
  guid: ModuleMap.Wetstock.View,

  path: '/Wetstock',
  redirectTo: '/Wetstock/Dashboard',

  icon: <LocalGasStationIcon />,

  modules: [
    {
      id: 'WetstockHub',
      guid: ModuleMap.Wetstock.Hub.View,

      path: '/Wetstock/Dashboard',
      Component: loadable(() => import('./Dashboard'), loadableConfig),

      icon: <DashboardIcon />,
      sidebarPath: '/Wetstock/Dashboard',
    },
    {
      id: 'WetstockHubSingle',
      guid: ModuleMap.Wetstock.Hub.View,

      path: '/Wetstock/Dashboard/:id',
      Component: loadable(() => import('./DashboardSingle'), loadableConfig),
    },

    {
      id: 'WetstockPricesViewer',
      guid: ModuleMap.Wetstock.PriceViewer.View,

      path: '/Wetstock/Prices',
      Component: loadable(() => import('./Prices'), loadableConfig),

      icon: <MoneyIcon />,
      sidebarPath: '/Wetstock/Prices',
    },
    {
      id: 'WetstockPricesViewerSingle',
      guid: ModuleMap.Wetstock.PriceViewer.View,
      path: '/Wetstock/Prices/:id',
      Component: loadable(() => import('./PricesSingle'), loadableConfig),
    },

    {
      id: 'WetstockDips',
      guid: ModuleMap.Wetstock.Dips.View,

      path: '/Wetstock/Dips',
      Component: loadable(() => import('./Dips'), loadableConfig),

      icon: <DoneAllIcon />,
      sidebarPath: '/Wetstock/Dips',
    },
    {
      id: 'WetstockDipsSingle',
      guid: ModuleMap.Wetstock.Dips.View,

      path: '/Wetstock/Dips/:id',
      Component: loadable(() => import('./DipsSingle'), loadableConfig),
    },

    {
      id: 'WetstockDeliveries',
      guid: ModuleMap.Wetstock.Deliveries.View,

      path: '/Wetstock/Deliveries',
      Component: loadable(() => import('./Deliveries'), loadableConfig),

      icon: <LocalShippingIcon />,
      sidebarPath: '/Wetstock/Deliveries',
    },
    {
      id: 'WetstockDeliveriesSingle',
      guid: ModuleMap.Wetstock.Deliveries.View,

      path: '/Wetstock/Deliveries/:id',
      Component: loadable(() => import('./DeliveriesSingle'), loadableConfig),
    },
  ],
};
