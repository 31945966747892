import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAbortSignal } from '@cbe/ui';
import { getData, APIResponse } from 'app/libs/axios';
import { APIM_SERVICE } from 'app/constants';

import { useAuthentication } from '../../Authentication';
import { AuthorisationError, IGetUser, IRawUserData } from '../types';
import { formatUserData, formatAppSettings } from './formatUserData';

/**
 * The `GetUserData` returns the users stores and roles and internally assigns the user
 * in the redis store for the backend services to be able to access the users data.
 */
async function getUserData(
  msalToken: string,
  signal: AbortSignal
): Promise<APIResponse<IRawUserData> | null> {
  return getData(
    {
      api: `${APIM_SERVICE.AUTH}/GetUserData`,
      config: {
        headers: { Authorization: `Bearer ${msalToken}` },
        signal,
      },
      defaultResponse: {} as IRawUserData,
    },
    2
  );
}

export const useUserData = (): (() => Promise<
  IGetUser | AuthorisationError | null
>) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation(['common'], { useSuspense: false });
  const { getToken, isAuthenticated } = useAuthentication();
  const { createController } = useAbortSignal();

  return useCallback(
    async function _getUser(): Promise<IGetUser | AuthorisationError | null> {
      if (!isAuthenticated) {
        return AuthorisationError.unauthenticated;
      }

      const token = await getToken();
      if (token) {
        const apiResponse = await getUserData(
          token,
          createController().current.signal
        );

        if (
          apiResponse?.data &&
          typeof apiResponse?.data?.userName === 'string'
        ) {
          if (apiResponse.data.stores.length === 0) {
            return AuthorisationError.stores;
          }

          const user = formatUserData(apiResponse.data);
          const appSettings = formatAppSettings(user);

          return {
            user,
            appSettings,
            _rawUserData: apiResponse.data,
          };
        } else if (apiResponse?.error) {
          enqueueSnackbar(
            ready
              ? t(`common:error.${apiResponse.error}`)
              : 'An Error Occurred',
            { variant: 'error' }
          );
        }
      }

      return null;
    },
    [createController, enqueueSnackbar, getToken, isAuthenticated, ready, t]
  );
};
