import { format as dateFormat } from 'date-fns';

/**
 * Function lowercase the string.
 * @example
 * ```json
 * {
 *   "key": "Formatted {{ VALUE, lowercase }}"
 * }
 * ```
 *
 * Usage: `t('key', { value: 'LOWERCASE STRING' })`
 *
 * Returns: `Formatted lowercase string`
 */
export const lowercase = (
  value: unknown,
  _lng: string | undefined,
  _options: unknown
): string => {
  if (typeof value === 'string') {
    return value.toLowerCase();
  }
  return `${value}`;
};

/**
 * Function uppercase the string.
 * @example
 * ```json
 * {
 *   "key": "Formatted {{ value, uppercase }}"
 * }
 * ```
 *
 * Usage: `t('key', { value: 'uppercase string' })`
 *
 * Returns: `Formatted UPPERCASE STRING`
 */
export const uppercase = (
  value: unknown,
  _lng: string | undefined,
  _options: unknown
): string => {
  if (typeof value === 'string') {
    return value.toUpperCase();
  }
  return `${value}`;
};

type CustomDateFnOptions = {
  format: string;
};

/**
 * Function to facilitate formatting of dates from the internationalisation library.
 * @example
 * ```json
 * {
 *   "key": "Formatted Date: {{ value, datefn(format: dd LLL yyyy HH:mm) }}",
 * }
 * ```
 *
 * Usage: `t('key', { value: new Date() })`
 *
 * Returns: `Formatted Date: 01 Jan 2020 00:00`
 */
export const datefn = (
  value: unknown,
  _lng: string | undefined,
  options: unknown
): string => {
  if (value instanceof Date) {
    /**
     * https://date-fns.org/v2.28.0/docs/format
     */
    return dateFormat(value, (options as CustomDateFnOptions)?.format);
  }

  return `${value}`;
};
