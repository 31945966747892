import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import InventoryIcon from '@mui/icons-material/Inventory';
import ListIcon from '@mui/icons-material/List';
import { BarcodeIcon } from '@cbe/ui';

export const ProductsRoutes: IRoute = {
  id: 'Products',
  guid: ModuleMap.Products.View,

  path: '/Products',

  icon: <InventoryIcon />,
  redirectTo: '/Products/List',

  modules: [
    {
      id: 'ProductsList',
      guid: ModuleMap.Products.View,

      path: '/Products/List',
      Component: loadable(
        () => import('./modules/ProductsList'),
        loadableConfig
      ),

      icon: <ListIcon />,
      sidebarPath: '/Products/List',
    },
    {
      id: 'ProductsLabelQueue',
      guid: ModuleMap.Products.LabelQueue,

      path: '/Products/LabelQueue',
      Component: loadable(() => import('./modules/LabelQueue'), loadableConfig),

      icon: <BarcodeIcon />,
      sidebarPath: '/Products/LabelQueue',
    },
  ],
};
