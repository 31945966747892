/** Location in the public folder where the forecourt branding images are stored. */
const FORECOURT_IMAGES_LOCATION = '/static/images/brands';

/**
 * FORECOURT_BRANDING is used to map a Wetstocks fuel brand to
 * one of the images in the IMAGES_LOCATION.
 */
const FORECOURT_BRANDING = new Map([
  [
    'bp',
    {
      name: 'BP',
      fileName: 'bp.png',
    },
  ],
  [
    'circle k',
    {
      name: 'Circle K',
      fileName: 'circle-k.png',
    },
  ],
  [
    'esso',
    {
      name: 'Esso',
      fileName: 'esso.png',
    },
  ],
  [
    'maxol',
    {
      name: 'Maxol',
      fileName: 'maxol.png',
    },
  ],
  [
    'shell',
    {
      name: 'Shell',
      fileName: 'shell.png',
    },
  ],
  [
    'sweeney oil',
    {
      name: 'Sweeney Oil',
      fileName: 'sweeney-oil.png',
    },
  ],
  [
    'texaco',
    {
      name: 'Texaco',
      fileName: 'texaco.png',
    },
  ],
  [
    'topaz',
    {
      name: 'Topaz',
      fileName: 'topaz.png',
    },
  ],
]);

export const FORECOURT_BRANDS = Array.from(FORECOURT_BRANDING.values());

export function getForecourtBrand(brand: string):
  | {
      name: string;
      fileName: string;
    }
  | undefined {
  if (brand) {
    return FORECOURT_BRANDING.get(`${brand}`.toLowerCase());
  }

  return undefined;
}

export function getForecourtLogo(brand: string): {
  name: string;
  url: string | null;
} {
  let brandName = null;
  let brandImage = null;

  const foundImage = getForecourtBrand(brand);
  if (foundImage) {
    brandName = foundImage.name;
    brandImage = `${FORECOURT_IMAGES_LOCATION}/${foundImage.fileName}`;
  }

  return {
    name: brandName || brand || '',
    url: brandImage,
  };
}
