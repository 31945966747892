import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';

import SettingsIcon from '@mui/icons-material/Settings';
import { ModuleMap } from 'app/constants';

export const SettingsRoutes: IRoute = {
  id: 'Settings',
  guid: ModuleMap.Settings.View,

  path: '/Settings',
  Component: loadable(() => import('./modules/HomePage'), loadableConfig),

  icon: <SettingsIcon />,
  sidebarPath: '/Settings',

  modules: [
    {
      id: 'SettingsSite',
      guid: ModuleMap.Settings.View,

      path: '/Settings/Setup-Site',
      Component: loadable(
        () => import('./modules/SiteManagement'),
        loadableConfig
      ),
    },
    {
      id: 'SettingsPaymentMethods',
      guid: ModuleMap.Settings.FuelCards.View,

      path: '/Settings/PaymentMethods',
      Component: loadable(
        () => import('./modules/PaymentMethods'),
        loadableConfig
      ),
    },
    {
      id: 'SettingsSuppliers',
      guid: ModuleMap.Settings.Suppliers.View,

      path: '/Settings/Suppliers',
      Component: loadable(() => import('./modules/Suppliers'), loadableConfig),
    },
    {
      id: 'ConfigureTaxRates',
      guid: ModuleMap.Settings.TaxRates.ViewModify,
      path: '/Settings/TaxRates',
      Component: loadable(() => import('./modules/TaxRates'), loadableConfig),
    },
    {
      id: 'ConfigureCashOffice',
      guid: ModuleMap.CashOffice.ModifySettings,

      path: '/Settings/CashOffice',
      Component: loadable(
        () => import('./modules/ConfigureCashOffice'),
        loadableConfig
      ),
      children: [
        {
          index: true,
          Component: loadable(
            () =>
              import(
                './modules/ConfigureCashOffice/modules/CashOfficeSettingsList'
              ),
            loadableConfig
          ),
        },
        {
          path: ':id',
          Component: loadable(
            () =>
              import(
                './modules/ConfigureCashOffice/modules/CashOfficeSettingsModify'
              ),
            loadableConfig
          ),
        },
        {
          path: 'OfficeBalancing',
          Component: loadable(
            () =>
              import(
                './modules/ConfigureCashOffice/modules/OfficeBalancingSettingsModify'
              ),
            loadableConfig
          ),
        },
      ],
    },
    {
      id: 'ConfigureProductHierarchy',
      guid: ModuleMap.Products.Hierarchy,

      path: '/Settings/ProductHierarchy',
      Component: loadable(
        () => import('./modules/ConfigureProductHierarchy'),
        loadableConfig
      ),
      children: [
        {
          index: true,
          Component: loadable(
            () =>
              import(
                './modules/ConfigureProductHierarchy/modules/ProductsHierarchyList'
              ),
            loadableConfig
          ),
        },
        {
          path: 'Edit',
          Component: loadable(
            () =>
              import(
                './modules/ConfigureProductHierarchy/modules/ProductsHierarchyModify'
              ),
            loadableConfig
          ),
        },
      ],
    },
    {
      id: 'ConfigureWholesalersSubscriptions',
      guid: ModuleMap.Wholesalers.ModifySettings,

      path: '/Settings/WholesalersSubscriptions',
      Component: loadable(
        () => import('./modules/WholesalersSubscriptions'),
        loadableConfig
      ),
      children: [
        {
          index: true,
          Component: loadable(
            () =>
              import(
                './modules/WholesalersSubscriptions/modules/WholesalersSubscriptionsList'
              ),
            loadableConfig
          ),
        },
        {
          path: ':id',
          Component: loadable(
            () =>
              import(
                './modules/WholesalersSubscriptions/modules/WholesalersSubscriptionDetails'
              ),
            loadableConfig
          ),
        },
      ],
    },
    {
      id: 'ConfigureCustomerAccounts',
      guid: ModuleMap.CustomerAccounts.ModifySettings,
      path: '/Settings/CustomerAccounts',
      Component: loadable(
        () => import('./modules/ConfigureCustomerAccounts'),
        loadableConfig
      ),
      children: [
        {
          index: true,
          Component: loadable(
            () =>
              import('./modules/ConfigureCustomerAccounts/SettingsContainer'),
            loadableConfig
          ),
        },
      ],
    },
    {
      id: 'ConfigureWetstockSettings',
      guid: ModuleMap.Wetstock.ModifySettings,
      path: '/Settings/Wetstock',
      Component: loadable(
        () => import('./modules/ModuleWetstockSettings/ModuleWetstockSettings'),
        loadableConfig
      ),
      children: [
        {
          index: true,
          Component: loadable(
            () =>
              import(
                './modules/ModuleWetstockSettings/pages/ModuleWetstockSettingsList'
              ),
            loadableConfig
          ),
        },
        {
          path: ':id',
          Component: loadable(
            () =>
              import(
                './modules/ModuleWetstockSettings/pages/ModuleWetstockSettingsModify'
              ),
            loadableConfig
          ),
        },
      ],
    },
  ],
};
