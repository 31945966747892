import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import PermMediaIcon from '@mui/icons-material/PermMedia';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

export const ContentManagerRoutes: IRoute = {
  id: 'ContentManager',
  guid: ModuleMap.ContentManager.View,

  path: '/ContentManager',
  redirectTo: '/ContentManager/Schedule',

  icon: <PermMediaIcon />,

  modules: [
    {
      id: 'ContentManagerSchedule',
      guid: ModuleMap.ContentManager.Schedule,

      path: '/ContentManager/Schedule',
      Component: loadable(() => import('./modules/Schedules'), loadableConfig),

      icon: <AssignmentOutlinedIcon />,
      sidebarPath: '/ContentManager/Schedule',
    },
    {
      id: 'ContentManagerMediaManagement',
      guid: ModuleMap.ContentManager.MediaManagement,

      path: '/ContentManager/MediaManagement',
      Component: loadable(
        () => import('./modules/MediaManagement'),
        loadableConfig
      ),

      icon: <PlayCircleIcon />,
      sidebarPath: '/ContentManager/MediaManagement',
    },
  ],
};
