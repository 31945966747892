import { __PROD__ } from './EnvironmentVars';

// Dev runtime options
//

/** Enable app insights tracking only if we're in a production build. */
export const ENABLE_APP_INSIGHTS = __PROD__;

// Actual feature flags
//
