/**
 * List of the AAD roles that a user can have assigned to their account.
 */
export const UserRole = {
  // User roles
  Standard: 'Standard',
  Admin: 'Admin',

  // Feature flagging role
  CBEPreview: 'CBEPreview',

  // Engineer roles
  CBEAdmin: 'CBEAdmin',
  CBESupport: 'CBESupport',
  CBESupportAdmin: 'CBESupportAdmin',
  CBESupportDev: 'CBESupportDev',
} as const;
