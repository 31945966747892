import { useContext, createContext } from 'react';
import { useReleaseNotesData } from './useReleaseNotesData';
import loadable from '@loadable/component';
import { loadableConfig } from 'app/routes/loadableConfig';

const ReleaseNotesDialog = loadable(
  () => import('./ReleaseNotesDialog'),
  loadableConfig
);

const ReleaseNotesContext = createContext<ReturnType<
  typeof useReleaseNotesData
> | null>(null);

export default function ReleaseNotesProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const releaseNotes = useReleaseNotesData();

  return (
    <ReleaseNotesContext.Provider value={releaseNotes}>
      {children}

      {releaseNotes.releaseNotesOpen && releaseNotes.data ? (
        <ReleaseNotesDialog />
      ) : null}
    </ReleaseNotesContext.Provider>
  );
}

export const useReleaseNotes = (): ReturnType<typeof useReleaseNotesData> => {
  const context = useContext(ReleaseNotesContext);
  if (!context) {
    throw new Error(
      'useReleaseNotes must be used within a ReleaseNotesProvider'
    );
  }
  return context;
};
