import React from 'react';
import styled from '@emotion/styled';
import type { PopoverContentProps } from '@reactour/tour';

export interface ITourPointContent {
  condition?: string[];
  list?: {
    text: string;
    condition?: string[];
  }[];
  type?: 'bulletPoints' | 'lineNumbers';
  video?: {
    title: string;
    link: string;
  };
  text?: string;
}

export const Container = styled.span`
  > br {
    display: block;
    content: '';
    margin-top: 0.5em;
    line-height: 190%;
  }
  > ul {
    padding-inline-start: 25px;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
  div.video-container {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: calc(1 / 1.7777 * 100%);

    > iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

interface IListContent {
  list: NonNullable<ITourPointContent['list']>;
  type: NonNullable<ITourPointContent['type']>;
  checkConditions: (condition: string[]) => boolean;
}

const ListContent = ({
  list,
  type,
  checkConditions,
}: IListContent): JSX.Element => {
  const Element = type === 'bulletPoints' ? 'ul' : 'ol';
  const listContent: JSX.Element[] = [];

  for (const { condition, text } of list) {
    if (!condition || checkConditions(condition) === true) {
      listContent.push(<li key={text}>{text}</li>);
    }
  }

  return <Element>{listContent}</Element>;
};

interface ITourPoint extends PopoverContentProps {
  data: ITourPointContent[];
  checkConditions: (condition: string[]) => boolean;
}

const TourPointContent = ({
  data,
  checkConditions,
}: ITourPoint): JSX.Element => {
  const pointContent: JSX.Element[] = [];

  for (let index = 0; index < data.length; index++) {
    const { condition, list, type, video, text } = data[index];

    if (!condition || checkConditions(condition) === true) {
      pointContent.push(
        <Container key={index}>
          {list ? (
            <ListContent
              list={list}
              type={type!}
              checkConditions={checkConditions}
            />
          ) : null}

          {video ? (
            <div className="video-container">
              <iframe
                title={video.title}
                src={video.link}
                frameBorder="0"
                allow="fullscreen"
              ></iframe>
            </div>
          ) : null}

          {text}

          {index + 1 !== data.length ? <br /> : null}
        </Container>
      );
    }
  }

  return <React.Fragment>{pointContent}</React.Fragment>;
};

export default TourPointContent;
