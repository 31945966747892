import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

export const EmployeesRoutes: IRoute = {
  id: 'Employees',
  guid: ModuleMap.Employees.View,

  path: '/Employees',
  Component: loadable(() => import('./'), loadableConfig),

  icon: <PeopleAltRoundedIcon />,
  sidebarPath: '/Employees',
};
