import { useContext } from 'react';
import AuthorisationContext from './AuthorisationContext';
import type { IAuthorisationContext } from './types';

const useAuthorisation = (): IAuthorisationContext => {
  const context = useContext(AuthorisationContext);
  if (!context) {
    throw new Error(
      'useAuthorisation should be used inside an AuthorisationContext'
    );
  }
  return context;
};

export default useAuthorisation;
