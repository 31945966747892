import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import DescriptionIcon from '@mui/icons-material/Description';

export const ReportsRoutes: IRoute = {
  id: 'Reports',
  guid: ModuleMap.Reports.View,

  path: '/Reports/:id?',
  Component: loadable(() => import('./'), loadableConfig),

  icon: <DescriptionIcon />,
  sidebarPath: '/Reports',
};
