import loadable from '@loadable/component';
import type { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import PaymentIcon from '@mui/icons-material/Payment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HistoryIcon from '@mui/icons-material/History';

export const CBEPayRoutes: IRoute = {
  id: 'CBEPay',
  guid: ModuleMap.CBEPay.View,

  path: '/CBEPay',
  redirectTo: '/CBEPay/Account',

  icon: <PaymentIcon />,

  modules: [
    {
      id: 'CBEPayAccount',
      guid: ModuleMap.CBEPay.View,

      path: '/CBEPay/Account',
      Component: loadable(
        () => import('./modules/Account/Account'),
        loadableConfig
      ),
      children: [
        {
          index: true,
          Component: loadable(
            () => import('./modules/Account/Components/AccountList/Account'),
            loadableConfig
          ),
        },
        {
          path: 'Summary',
          Component: loadable(
            () => import('./modules/Account/Components/SummaryList/Summary'),
            loadableConfig
          ),
        },
      ],
      icon: <AssignmentIcon />,
      sidebarPath: '/CBEPay/Account',
    },
    {
      id: 'CBEPayTransactions',
      guid: ModuleMap.CBEPay.Transactions,

      path: '/CBEPay/Transactions',
      Component: loadable(
        () => import('./modules/Transactions/Transactions'),
        loadableConfig
      ),

      icon: <ReceiptIcon />,
      sidebarPath: '/CBEPay/Transactions',
    },
    {
      id: 'CBEPayPayouts',
      guid: ModuleMap.CBEPay.Payouts,

      path: '/CBEPay/Payouts',
      Component: loadable(
        () => import('./modules/Payouts/Payouts'),
        loadableConfig
      ),

      icon: <AccountBalanceIcon />,
      sidebarPath: '/CBEPay/Payouts',
    },
    {
      id: 'CBEPayChargebacks',
      guid: ModuleMap.CBEPay.Chargebacks,

      path: '/CBEPay/Chargebacks',
      Component: loadable(
        () => import('./modules/Chargebacks/Chargebacks'),
        loadableConfig
      ),

      icon: <HistoryIcon />,
      sidebarPath: '/CBEPay/Chargebacks',
    },
  ],
};
