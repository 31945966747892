import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import { PowerBIIcon } from '@cbe/ui';

export const RetailBIRoutes: IRoute = {
  id: 'RetailBI',
  guid: ModuleMap.RetailBI.View,

  path: '/RetailBI/:type?/:id?',
  Component: loadable(() => import('./'), loadableConfig),

  icon: <PowerBIIcon />,
  sidebarPath: '/RetailBI',
};
