import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import LockIcon from '@mui/icons-material/Lock';
import useAuthorisation from './useAuthorisation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div.icon {
    display: flex;
    justify-content: center;
    padding-bottom: ${({ theme }) => theme.spacing(4)};
    > svg {
      width: 50px;
      height: 50px;
    }
  }
  div.message {
    display: flex;
    flex-direction: column;
    align-items: center;

    > h3 {
      padding-bottom: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

const UserUnauthorisedMessage = (): JSX.Element => {
  const { t } = useTranslation(['common']);

  return (
    <Container id="user-unauthorised">
      <div className="icon">
        <LockIcon />
      </div>

      <div className="message">
        <Typography variant="h4" component="h3">
          {t('common:auth.module-access')}
        </Typography>

        <Typography variant="body1">
          {t('common:auth.contact-administrator')}
        </Typography>
      </div>
    </Container>
  );
};

export const ModuleAuthorisationWrapper = ({
  Component,
  guid,
  roles,
}: {
  Component: React.ComponentType;
  guid: string | undefined;
  roles: string[] | undefined;
}): JSX.Element => {
  const { checkUserHasRole, checkUserHasPrivilege } = useAuthorisation();

  // If no guid is passed in then we assume the route doesn't depend on modules.
  const validModule = guid === undefined ? true : checkUserHasPrivilege(guid);

  // If no roles are passed in then we assume the route doesn't depend on roles.
  const validRole = roles ? checkUserHasRole(roles) !== false : true;

  if (!validRole || !validModule) {
    return <UserUnauthorisedMessage />;
  }

  return <Component />;
};
