import { useContext } from 'react';
import ModuleTourContext from './ModuleTourContext';
import type { IModuleTourProvider } from './types';

const useModuleTourProvider = (): IModuleTourProvider => {
  const context = useContext(ModuleTourContext);
  if (!context) {
    throw new Error(
      'useModuleTourProvider should be used inside an ModuleTourProvider'
    );
  }

  return context;
};

export default useModuleTourProvider;
