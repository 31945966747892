import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { components, PopoverContentProps } from '@reactour/tour';
import type { IStepsData } from '../types';

const { Navigation } = components; // Destructure Navigation from the default components.

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.paper};

  div.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 12px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};

    div.label-container {
      display: flex;
      align-items: center;

      > span.step-label {
        padding: 6px 12px;
        border-radius: 6px;
        background-color: ${({ theme }) => theme.palette.primary.dark};
        font-size: 0.8rem;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
      > span.title {
        margin-left: 12px;
        font-size: 0.95rem;
        font-weight: 700;
      }
    }
  }
  div.content-container {
    padding: 12px 24px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
  div.nav-container {
    margin: 18px 0;
  }
`;

const ContentComponent = (props: PopoverContentProps): JSX.Element => {
  const { t } = useTranslation(['common']);

  const { currentStep, steps, setIsOpen } = props;
  const step = steps[currentStep];
  const StepContent = step.content;

  return (
    <Container className="tour-content-component">
      <div className="header-container">
        <div className="label-container">
          <span className="step-label">
            {t('common:tour.step', { number: currentStep + 1 })}
          </span>

          {(step as IStepsData).stepOptions.title ? (
            <span className="title">
              {(step as IStepsData).stepOptions.title}
            </span>
          ) : null}
        </div>

        <div className="close-button-container">
          <IconButton
            onClick={() => setIsOpen(false)}
            aria-label={t('common:buttons.close')}
            id="close-tour-button"
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className="content-container">
        {typeof StepContent === 'function' ? (
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          <StepContent {...props} />
        ) : (
          StepContent
        )}
      </div>

      <div className="nav-container">
        <Navigation {...props} />
      </div>
    </Container>
  );
};

export default ContentComponent;
