import React, { useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import 'simplebar-react/dist/simplebar.min.css';

import {
  DashboardLayout,
  useNavigationState,
  DashboardMain,
  DashboardSidebar,
  DashboardHeader,
  INavigationItem,
} from '@cbe/ui';

import { HeaderTourButton } from './HeaderTourButton';
import { HeaderPowerButton } from './HeaderPowerButton';
import { MainLinks } from './MainLinks';

import { useAuthentication } from 'app/features/Authentication';
import { routes, IRoute } from 'app/routes';
import { IUserData, useAuthorisation } from 'app/features/Authorisation';

function renderNavItem(
  {
    id,
    guid,
    roles,
    modules = [],
    icon,
    sidebarPath,
    shouldShowInSidebar,
  }: IRoute,
  getRouteLabel: (item: string) => string,
  checkUserHasPrivilege: (moduleGuid: string) => boolean,
  checkUserHasRole: (roles: string[]) => false | string[],
  user: IUserData
): INavigationItem | null {
  if (!sidebarPath && modules.length === 0) {
    return null;
  }

  // Should the route be displayed in the sidebar?
  const shouldShow = shouldShowInSidebar ? shouldShowInSidebar(user) : true;

  // If no guid is passed in then we assume the route doesn't depend on modules.
  const validModule = guid === undefined ? true : checkUserHasPrivilege(guid);

  // If no roles are passed in then we assume the route doesn't depend on roles.
  const validRole = roles ? checkUserHasRole(roles) !== false : true;

  if (!shouldShow || !validRole || !validModule) {
    return null;
  }

  const navItem: INavigationItem = {
    id,
    path: sidebarPath,
    label: getRouteLabel(id),
    icon: icon || <React.Fragment></React.Fragment>,
  };

  // Recursively map modules onto this function to check if there is any further routes.
  const childRoutes = modules
    .map((child) =>
      renderNavItem(
        child,
        getRouteLabel,
        checkUserHasPrivilege,
        checkUserHasRole,
        user
      )
    )
    .filter(Boolean) as INavigationItem[];

  if (childRoutes.length > 0) {
    navItem.children = childRoutes;
  }

  return navItem;
}

export default memo(function _DashboardLayout({
  children,
}: {
  children: React.ReactElement;
}): JSX.Element {
  const { t } = useTranslation(['common']);
  const { account } = useAuthentication();
  const { user, getFirstInstanceOf, checkUserHasPrivilege, checkUserHasRole } =
    useAuthorisation();

  const navItems = useMemo<INavigationItem[]>(() => {
    function getRouteLabel(id: string) {
      return t(`common:page.${id}` as any);
    }

    const _items = [];
    if (user) {
      for (const route of routes) {
        const _item = renderNavItem(
          route,
          getRouteLabel,
          checkUserHasPrivilege,
          checkUserHasRole,
          user
        );

        if (_item) _items.push(_item);
      }
    }
    return _items;
  }, [user, t, checkUserHasPrivilege, checkUserHasRole]);

  const navigation = useNavigationState(navItems);

  return (
    <DashboardLayout navigation={navigation}>
      <DashboardHeader
        navigation={navigation}
        MenuComponent={
          <React.Fragment>
            <HeaderTourButton />

            <HeaderPowerButton />
          </React.Fragment>
        }
        shopBranding={getFirstInstanceOf('shopBranding') || undefined}
      />

      <DashboardSidebar
        navigation={navigation}
        usersName={account?.name || ''}
        organisationName={getFirstInstanceOf('organisation')?.name || 'Unknown'}
        shopBranding={getFirstInstanceOf('shopBranding') || undefined}
      />

      <DashboardMain navigation={navigation} LinkComponent={<MainLinks />}>
        {children}
      </DashboardMain>
    </DashboardLayout>
  );
});
