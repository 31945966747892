import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TourProvider, PopoverContentProps } from '@reactour/tour';
import { IconButton, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import type { IModuleTourProvider, IStepsData, ITourSettings } from './types';
import ModuleTourContext from './ModuleTourContext';
import { getTourStyles } from './styles/getTourStyles';
import ContentComponent from './components/ContentComponent';

const ModuleTourProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { t, ready } = useTranslation(['common'], { useSuspense: false });
  const theme = useTheme();
  const tourStyles = useMemo(() => getTourStyles(theme), [theme]);

  const [initialSteps] = useState([]);
  const [tourSettings, setTourSettings] = useState<ITourSettings>({
    type: null,
    moduleSteps: {
      allSteps: [],
      whatsNew: [],
    },
  });

  const [disableKeyboardNavigation, setDisableKeyboardNavigation] =
    useState(false);

  const Memoed = useMemo<IModuleTourProvider>(() => {
    function handleOpenTour(type: ITourSettings['type']) {
      setTourSettings((prevState) => ({
        ...prevState,
        type: type,
      }));
    }

    function handleStepsData(allSteps: IStepsData[], whatsNew: IStepsData[]) {
      setTourSettings((prevState) => ({
        ...prevState,
        moduleSteps: {
          allSteps,
          whatsNew,
        },
      }));
    }

    return {
      tourSettings,
      handleOpenTour,
      handleStepsData,
      setDisableKeyboardNavigation,
    };
  }, [tourSettings]);

  const handleBeforeClose = useCallback(() => {
    setTourSettings((prevState) => ({
      ...prevState,
      type: null,
    }));
  }, []);
  const ContentComponentCB = useCallback(
    (props: PopoverContentProps) => <ContentComponent {...props} />,
    []
  );

  const PreviousButton = useCallback(
    (prop: Parameters<NonNullable<PopoverContentProps['prevButton']>>[0]) => {
      return (
        <IconButton
          disabled={prop.currentStep === 0}
          onClick={() => prop.setCurrentStep((prevState) => --prevState)}
          aria-label={ready ? t('common:action.go-to-previous-step') : ''}
        >
          <ArrowBackIcon />
        </IconButton>
      );
    },
    [ready, t]
  );
  const NextButton = useCallback(
    (prop: Parameters<NonNullable<PopoverContentProps['nextButton']>>[0]) => {
      return (
        <IconButton
          disabled={prop.currentStep === prop.stepsLength - 1}
          onClick={() => prop.setCurrentStep((prevState) => ++prevState)}
          aria-label={ready ? t('common:action.go-to-next-step') : ''}
        >
          <ArrowForwardIcon />
        </IconButton>
      );
    },
    [ready, t]
  );

  return (
    <TourProvider
      steps={initialSteps}
      beforeClose={handleBeforeClose}
      disableKeyboardNavigation={
        disableKeyboardNavigation ? ['esc', 'right', 'left'] : []
      }
      className="tour-point-container"
      maskClassName="tour-point-mask"
      styles={tourStyles}
      ContentComponent={ContentComponentCB}
      disableFocusLock
      prevButton={PreviousButton}
      nextButton={NextButton}
    >
      <ModuleTourContext.Provider value={Memoed}>
        {children}
      </ModuleTourContext.Provider>
    </TourProvider>
  );
};

export default ModuleTourProvider;
