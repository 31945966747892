import create from 'zustand';
import { getLocale, ILocale } from './getLocale';

const CollatorOptions: Parameters<typeof Intl.Collator>[1] = {
  numeric: true,
  sensitivity: 'base',
};

interface IUseLocale {
  locale: ILocale;
  localeSort: Intl.Collator;
  /**
   * ISO 639-1 standard language code
   * @link https://www.w3.org/International/ms-lang.html
   *
   * @example
   * setLocale('en-IE')
   */
  setLocale: (newLocale: string) => void;
}

export const useLocale = create<IUseLocale>((set) => ({
  locale: getLocale('en-IE'),
  localeSort: new Intl.Collator('en-IE', CollatorOptions),
  setLocale: (newLocale: string) => {
    set(() => ({
      locale: getLocale(newLocale),
      localeSort: new Intl.Collator(newLocale, CollatorOptions),
    }));
  },
}));
