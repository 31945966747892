import { memo } from 'react';
import loadable from '@loadable/component';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { LoginLayout } from 'app/components/LoginLayout';
import { ErrorComponent } from 'app/components/ErrorComponent';
import { loadableConfigAuth } from './loadableConfig';
import { renderRoutes, IRoute } from './renderRoutes';
import { AuthenticationWrapper } from 'app/features/Authentication';

import { DashboardRoutes } from 'app/pages/Dashboard/routes';
import { JournalsRoutes } from 'app/pages/Journals/routes';
import { PromotionsRoutes } from 'app/pages/Promotions/routes';
import { WholesalersRoutes } from 'app/pages/Wholesalers/routes';
import { ProductsRoutes } from 'app/pages/Products/routes';
import { FulfilmentRoutes } from 'app/pages/Fulfilment/routes';
import { CommunicationCentreRoutes } from 'app/pages/CommunicationCentre/routes';
import { ContentManagerRoutes } from 'app/pages/ContentManager/routes';
import { CashOfficeRoutes } from 'app/pages/CashOffice/routes';
import { CBEPayRoutes } from 'app/pages/CPEPay/routes';
import { CustomerAccountsRoutes } from 'app/pages/CustomerAccounts/routes';
import { ReportsRoutes } from 'app/pages/Reports/routes';
import { RetailBIRoutes } from 'app/pages/PowerBi/routes';
import { WetstockRoutes } from 'app/pages/Wetstock/routes';
import { EmployeesRoutes } from 'app/pages/Employees/routes';
import { SettingsRoutes } from 'app/pages/Settings/routes';

const Login = loadable(() => import('app/pages/Login'), loadableConfigAuth);
const Redirect = loadable(
  () => import('app/pages/Redirect'),
  loadableConfigAuth
);
const TermsOfContract = loadable(
  () => import('app/pages/TermsOfContract'),
  loadableConfigAuth
);
const PageNotFound = loadable(
  () => import('app/pages/Errors/PageNotFound'),
  loadableConfigAuth
);

/**
 * `routes` is the array of all routes inside of the application.
 *
 * The order of the routes array is the order the routes will be displayed in the sidebar navigation.
 */
export const routes: IRoute[] = [
  DashboardRoutes,
  JournalsRoutes,
  PromotionsRoutes,
  WholesalersRoutes,
  ProductsRoutes,
  FulfilmentRoutes,
  CommunicationCentreRoutes,
  CashOfficeRoutes,
  CBEPayRoutes,
  CustomerAccountsRoutes,
  ReportsRoutes,
  RetailBIRoutes,
  WetstockRoutes,
  EmployeesRoutes,
  ContentManagerRoutes,
  SettingsRoutes,
];

const router = createBrowserRouter([
  {
    index: true,
    element: (
      <LoginLayout>
        <Login />
      </LoginLayout>
    ),
    ErrorBoundary: ErrorComponent,
  },
  {
    path: '/Redirect/*',
    element: (
      <LoginLayout>
        <Redirect />
      </LoginLayout>
    ),
    ErrorBoundary: ErrorComponent,
  },
  {
    path: '/TermsOfContract',
    element: (
      <AuthenticationWrapper>
        <TermsOfContract />
      </AuthenticationWrapper>
    ),
    ErrorBoundary: ErrorComponent,
  },
  ...renderRoutes(routes),
  {
    path: '*',
    element: (
      <AuthenticationWrapper>
        <PageNotFound />
      </AuthenticationWrapper>
    ),
    ErrorBoundary: ErrorComponent,
  },
]);

export const AppRoutes = memo(function _AppRoutes() {
  return <RouterProvider router={router} />;
});
