import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'app/components/Info/Tooltip';

import { useAuthentication } from 'app/features/Authentication';
import {
  getSavedTour,
  StartAppTourButton,
  TourNotification,
  updateSavedTour,
  useModuleTour,
} from 'app/features/ModuleTour';

export const HeaderTourButton = (): JSX.Element => {
  const { t } = useTranslation(['common']);
  const { account } = useAuthentication();
  const { handleOpenTour, shouldShowTour } = useModuleTour();

  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (shouldShowTour.module === true && account?.id) {
      const storedModule = getSavedTour(account.id, 'module');

      // If no stored module, show the notification and update the saved tour.
      if (storedModule === null) {
        setShowNotification(true);

        updateSavedTour(account.id, 'module');
      }
    }

    return () => {
      setShowNotification(false);
    };
  }, [account, shouldShowTour.module]);

  if (shouldShowTour.module === true) {
    return (
      <Tooltip title={t('common:header.tour-button-title')}>
        <div>
          <TourNotification isEnabled={showNotification}>
            <StartAppTourButton
              onClick={() => handleOpenTour('module')}
              aria-label={t('common:header.tour-button-title')}
            />
          </TourNotification>
        </div>
      </Tooltip>
    );
  }

  return <React.Fragment></React.Fragment>;
};
