import { IconButton, IconButtonProps } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

/**
 * Start app tour button is used in the navigation bar at the top of
 * the application which can be used to trigger the modules tour
 * from any point in the usage of the app.
 */
export const StartAppTourButton = ({
  color = 'inherit',
  id = 'start-cloud-tour-button',
  ...rest
}: IconButtonProps): JSX.Element => {
  return (
    <IconButton color={color} id={id} size="large" {...rest}>
      <HelpOutlineIcon />
    </IconButton>
  );
};
