import { memo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  > p {
    margin: 2px 0;
    &.bold {
      font-weight: bold;
      font-size: 15px;
    }
  }
`;

export const MissingFeatureMessage = memo((): JSX.Element => {
  const { t } = useTranslation(['common']);

  return (
    <Container>
      <p className="bold">{t('common:auth.feature-access')}</p>
      <p>{t('common:auth.contact-administrator')}</p>
    </Container>
  );
});
