export interface ILocale {
  /**
   * ISO 639-1 standard language code
   * https://www.w3.org/International/ms-lang.html
   *
   * @default "en-IE"
   */
  type: string;

  currency: {
    id: string;
    name: string;
    symbol: string;
    display: {
      /** Decimal places to show wetstock values by. */
      wetstock: {
        cost: number;
        price: number;
      };
    };
  };

  dateTime: {
    date: string;
    time: string;
    long: string;
    pretty: string;
    export: string;
  };

  liquids: {
    symbol: string;
    name: string;
    abbreviation: string;
    costPerUnit: string;
  };

  period: {
    '%LAST7DAYS': string;
    '%TODAY': string;
  };

  transaction: {
    ACCOUNT: string;
    CASH: string;
    CARD: string;
    CREDITCARD: string;
    '%CASH': string;
    '%CREDITCARDS': string;
    '%FUELCARDS': string;
  };
}

const common: Pick<ILocale, 'dateTime' | 'period' | 'transaction'> = {
  dateTime: {
    date: 'dd/MM/yyyy',
    time: 'HH:mm:ss',
    long: 'dd/MM/yyyy HH:mm:ss',
    pretty: 'dd LLL yyyy HH:mm',
    export: 'dd_MMM_yyyy-HH_mm_ss',
  },

  period: {
    '%LAST7DAYS': 'Last 7 Days',
    '%TODAY': 'Today',
  },

  transaction: {
    ACCOUNT: 'Account',
    CASH: 'Cash',
    CARD: 'Card',
    CREDITCARD: 'Credit Card',
    '%CASH': 'Cash',
    '%CREDITCARDS': 'Credit Card',
    '%FUELCARDS': 'Fuel Card',
  },
};

function getLiquidDetails(type: 'gallons' | 'litres'): ILocale['liquids'] {
  switch (type) {
    case 'gallons':
      return {
        symbol: 'gal',
        name: 'Gallons',
        abbreviation: 'gal',
        costPerUnit: 'cpg',
      };

    default:
      return {
        symbol: 'ℓ',
        name: 'Litres',
        abbreviation: 'ltr',
        costPerUnit: 'cpl',
      };
  }
}

export function getLocale(locale: string): ILocale {
  switch (locale.toLowerCase()) {
    case 'en-us': {
      return {
        ...common,

        type: 'en-us',
        currency: {
          id: 'USD',
          name: 'Dollar',
          symbol: '$',
          display: {
            wetstock: {
              cost: 4,
              price: 3,
            },
          },
        },
        liquids: getLiquidDetails('gallons'),
      };
    }

    case 'en-gb': {
      return {
        ...common,

        type: 'en-gb',
        currency: {
          id: 'GPB',
          name: 'Pound',
          symbol: '£',
          display: {
            wetstock: {
              cost: 4,
              price: 3,
            },
          },
        },
        liquids: getLiquidDetails('litres'),
      };
    }

    default: {
      return {
        ...common,

        type: 'en-ie',
        currency: {
          id: 'EUR',
          name: 'Euro',
          symbol: '€',
          display: {
            wetstock: {
              cost: 4,
              price: 3,
            },
          },
        },
        liquids: getLiquidDetails('litres'),
      };
    }
  }
}
