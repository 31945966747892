/**
 * Map of the applications features and their module IDs.
 */
export const ModuleMap = {
  CashOffice: {
    View: '46926414-626b-41b2-a5f1-680eeb40670e',
    Hub: '50f6ddd3-6206-4089-ae01-1aa3b8e13409',
    Drawers: 'fc400191-a99b-4a78-9685-c04a3c2b6123',
    Declarations: '05346446-85fa-4a54-a2bd-90933b824d9e',
    OfficeBalancing: 'd28ca6e6-aa01-4b2c-8b5c-077374127c00',
    BankBalancing: '820b3502-aa94-482f-98ca-7937efa75a21',
    ModifySettings: '4a35617a-04f2-42f1-a948-3ea6393b57d9',
  },

  CBEPay: {
    View: '4fc8d545-e459-4a2e-95e3-39bf2d45d13b',
    Transactions: '5f89b515-c545-4017-b270-378976b2ba7e',
    Payouts: '10bd4638-6ffa-438a-a4c9-9555805c7993',
    Chargebacks: 'fd288f03-198a-408f-87ba-baef35571607',
  },

  CommunicationCentre: {
    View: '47ea3bf1-d5d1-45f8-a522-9477dd3af02f',
    Send: '8eaa6003-645a-4c76-b9e5-50f5e20c56d1',
    Delete: '020a1961-5630-4de4-bc26-c271ec7d344d',
  },

  ContentManager: {
    View: '4429ddad-3737-4310-a0ef-46f1aeb4050e',
    Schedule: '824acf33-5e07-4599-8211-72b413d9b68c',
    MediaManagement: 'c0409310-29c3-472d-97da-e349c505d0bc',
  },

  CustomerAccounts: {
    View: '9c85ab47-5f07-44e9-ac53-d5802ac11b7a',
    Hub: '148f6c74-2169-42ec-8ed6-493646078aca',
    Customers: '306ccead-3ec6-4844-a286-a6d4dde601d9',
    StatementOfAccounts: '30a03f1c-e400-4ecf-a31a-110774bf591d',
    ModifySettings: '07f2cfea-973b-4820-9687-62cc2e1dca59',
  },

  Dashboard: {
    View: '62aec3a0-ed7f-40e6-99e7-e250f98cc546',
  },

  Employees: {
    View: 'ea13ba2e-1176-47c9-b090-ed83f7bc927a',
  },

  Fulfilment: {
    View: '8c8ae2da-2da0-4da2-abcd-4ecdd7cde9d9',
    Orders: '69cb8e81-2f79-4ba1-9bb2-78183262ebd2',
    Deliveries: '4bbdefab-2d7e-45ca-9ff0-9133740d9dd8',
    Returns: '861fc2b5-9684-4c99-8678-127861b8475f',
  },

  Journals: {
    View: 'ba897661-39d9-405d-b5f9-8710bfc16422',
    ModifyTemplates: '0be300b5-dc0b-4162-bc6d-44c3b8f0c3cf',
  },

  Products: {
    View: '0026b4dd-b32d-4b1c-9597-04052b837661',
    Hierarchy: '0980127b-a0ef-4efb-86b5-8f6e5fbe7383',
    LabelQueue: '871a7092-8836-4a74-89aa-6a38ce3d17c5',
  },

  Promotions: {
    View: 'b724daef-1dbb-4a49-833b-3e6e42155f9e',
    PromotionGroups: '723b0868-8c88-4d89-b93f-ee78edac9d17',
    AddPromotion: 'bf5eb744-c01e-4824-8279-1da9345336da',
  },

  Reports: {
    View: 'aa059031-2db9-4afa-9d62-46cf62c1a557',
  },

  RetailBI: {
    View: '423bd723-7b76-47e3-9e71-b71abbad28a3',
  },

  Wetstock: {
    View: 'e072ec5b-1951-411a-8878-e1eca63cfd7f',
    Hub: {
      View: '44bc6f8a-a762-4c20-b7f3-2920a507ffcb',
    },
    PriceViewer: {
      View: 'da66d6f0-3483-4133-aa78-7ae2b700f75c',
      Modify: '844af241-806f-46fc-8693-5fdef53b6e6d',
    },
    Dips: {
      View: 'acf89d2a-5e22-4ffb-89be-3280bb4bbd50',
      Modify: '1514e407-0bcf-4772-a6a4-24e62d76693c',
      ReadGauges: '948d9e18-e43e-4db9-8578-0e459dc680c4',
    },
    Deliveries: {
      View: '38a2b36a-097a-4852-a6c0-a05cf21312c7',
      Modify: 'f63483af-7d3e-486e-a54e-cfcdd129d25a',
    },
    ModifySettings: '3402daf2-502a-46d8-852c-11c573e4067e',
  },

  Wholesalers: {
    View: '0f6df22a-1a7b-4b18-86df-3ef7a44ae59b',
    Imports: '35a26561-59dc-4510-8849-283c96917cb8',
    Exports: 'fd531061-a2c0-4d82-8f22-d5c6983e1819',
    ModifySettings: '47439c55-94df-4519-8ecc-88293f060f5a',
  },

  Settings: {
    View: '33cb7bbe-5949-4386-bb99-403e6f5d6fff',
    SiteDetails: {
      ViewModify: '64c80999-c54a-470b-bb5b-99b3db56af25',
    },
    TaxRates: {
      ViewModify: '7391871e-9d90-4846-a14a-578b203cf64b',
    },
    Suppliers: {
      View: '7e019f23-dc68-41cf-98a3-152a9bf26571',
      Modify: 'ac5f262a-744e-48b6-8112-051028bdce72',
    },
    FuelCards: {
      View: '2ad38b72-2db6-45e5-8a33-e8ca86a121d0',
      Modify: '8e516e57-6f85-496d-83f0-0e031b564c8f',
    },
  },
} as const;
