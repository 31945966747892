import { Tooltip as MuiTooltip, TooltipProps, Typography } from '@mui/material';

interface ITooltip extends Omit<TooltipProps, 'arrow'> {
  children: JSX.Element;
  title: string | JSX.Element;
  /**
   * The id is placed onto the titles typography.
   */
  id?: string;
  /**
   * If `true`, the tooltip will be disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, removes the arrow on the tooltip.
   * @default false
   */
  removeArrow?: boolean;
}

const Tooltip = ({
  children,
  title,
  id = 'tooltip',
  disabled = false,
  removeArrow = false,
  ...rest
}: ITooltip): JSX.Element => {
  return (
    <MuiTooltip
      title={
        <Typography
          variant="caption"
          component="span"
          className={`${id}-title`}
        >
          {title}
        </Typography>
      }
      id={id}
      arrow={!removeArrow}
      enterDelay={250}
      leaveDelay={100}
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
