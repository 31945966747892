import { createAxiosClient } from '@cbe/ui';
import type {
  APIError,
  APIResponse,
  DurableTaskCallback,
  DurableTaskDeviceResponse,
  DurableTaskRequest,
  DurableTaskStatus,
} from '@cbe/ui';
import { APIM_URI, APP_VERSION } from 'app/constants';
import { appInsights } from 'app/features/Analytics/AppInsightsService';

const { client, getData, postData, getDurableTaskProgress, notifyDurableTask } =
  createAxiosClient({
    baseURL: APIM_URI,
    trackException: (error) => appInsights.trackException(error),
    customExceptionProperties: {
      appName: 'CBE Cloud',
      appVersion: APP_VERSION!,
    },
  });

export { client, getData, postData, getDurableTaskProgress, notifyDurableTask };
export type {
  APIError,
  APIResponse,
  DurableTaskCallback,
  DurableTaskDeviceResponse,
  DurableTaskRequest,
  DurableTaskStatus,
};
