import { Navigate, RouteObject } from 'react-router-dom';
import type loadable from '@loadable/component';
import { DashboardLayout } from 'app/components/DashboardLayout';
import { ErrorComponent } from 'app/components/ErrorComponent';
import { AuthenticationWrapper } from 'app/features/Authentication/AuthenticationWrapper';
import {
  IUserData,
  ModuleAuthorisationWrapper,
} from 'app/features/Authorisation';

export interface IRoute {
  id: string;
  /**
   * Unique identifier for the module, The same guid is shared across all environments.
   *
   * {@link https://dev.azure.com/CBESoftware/Cloud%20Retail/_wiki?pageId=100&friendlyName=CBE-Cloud-Module-Access# Module access details defined in Wiki}
   */
  guid: string | undefined;
  /**
   * Roles that are allowed to access the route.
   */
  roles?: string[];

  /**
   * Path to make this route active.
   */
  path: string;
  /**
   * If `redirectTo` is set then the route will redirect from the given path and `Component` prop will ignored.
   */
  redirectTo?: string;
  /**
   * Loadable component to be rendered.
   */
  Component?: ReturnType<typeof loadable>;
  /**
   * {@link https://reactrouter.com/en/main/route/loader React Router - loader}
   */
  loader?: RouteObject['loader'];
  /**
   * Children routes to be rendered using an `<Outlet />` component.
   *
   * {@link https://reactrouter.com/en/main/route/route#children React Router - Children}
   *
   * {@link https://reactrouter.com/en/main/components/outlet React Router - Outlet}
   */
  children?: RouteObject[];

  /**
   * Icon to be displayed in the sidebar.
   */
  icon?: JSX.Element;
  /**
   * Path required for the navigation link in to be displayed in the sidebar.
   */
  sidebarPath?: string;
  /**
   * Runtime logic to determine if the route should be displayed in the sidebar depending on the users data.
   */
  shouldShowInSidebar?: (user: IUserData) => boolean;

  modules?: IRoute[];
}

function renderRoute({
  id,
  guid,
  roles,

  path,
  redirectTo,
  Component,
  loader,
  children,
}: IRoute): RouteObject | undefined {
  if (redirectTo) {
    return {
      id,
      path,
      element: <Navigate replace to={redirectTo} />,
    };
  }

  if (Component) {
    return {
      id,
      path,
      loader,
      children,
      element: (
        <AuthenticationWrapper>
          <DashboardLayout>
            <ModuleAuthorisationWrapper
              Component={Component}
              guid={guid}
              roles={roles}
            />
          </DashboardLayout>
        </AuthenticationWrapper>
      ),
      ErrorBoundary: ErrorComponent,
    };
  }
}

export function renderRoutes(routes: IRoute[]): RouteObject[] {
  const appRoutes: RouteObject[] = [];

  for (const route of routes) {
    const renderedRoute = renderRoute(route);

    if (renderedRoute) {
      appRoutes.push(renderedRoute);
    }
    if (route.modules) {
      // We want to flatten all routes/modules into a single array of routes.
      appRoutes.push(...renderRoutes(route.modules));
    }
  }

  return appRoutes;
}
