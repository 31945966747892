import type { IOrganisationData, ISiteData } from '../types';

export const DEFAULT_ORGANISATION_DATA: IOrganisationData = {
  id: '',
  name: 'Unknown Organisation',
  cultureInfoName: 'en-IE',
  defaultSiteId: '',
  logo: null,

  address: '',
  apim: null,
  contact: '',
  email: null,
  maxSites: null,
  maxUsers: null,
  phone: null,

  powerBIWorkspaceID: null,
  subscriptionRenewaDate: null,

  hasExternallyManagedUsers: false,
  isEnabled: false,
  isExtraHierarchyVisible: null,
  isManagedAzureAD: false,

  calendarWeekRule: 0,
  firstDayOfTheWeek: 1,
};

export const DEFAULT_SITE_DATA: ISiteData = {
  id: '',
  name: 'Unknown Site',
  cultureInfoName: 'en-IE',
  landingPage: '',
  logo: null,
  organisation: DEFAULT_ORGANISATION_DATA,
  organisationId: '',
  theme: '',

  address: '',
  areaManager: '',
  azureGroupKey: '',
  contact: '',
  email: '',
  forecourtBranding: '',
  latitude: 0,
  location: '',
  longitude: 0,
  phone: '',
  region: '',
  rep: '',
  shopBranding: '',
  squareFootage: 0,
  subscriptionRenewaDate: null,

  autoFuelLicence: null,
  vatLicence: null,
  devices: null,
  dipTimeoutInSeconds: null,
  markedFuelLicence: null,
  priceTimeoutInSeconds: null,

  is24Hour: null,
  isBunkeringEnabled: null,
  isBunkeringIncluded: null,
  isDistributor: null,
  isEnabled: false,
  isForecourtRetailer: false,
  isWarehouseKeeper: null,
};
