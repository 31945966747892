/**
 * Number of days to consider a tour step as new
 */
export const DAYS_TO_CONSIDER_NEW = 60;
/**
 * Number of days to show the whats new tour button
 * to the user
 */
export const DAYS_TO_SHOW_WHATS_NEW_TO_USER = 7;
/**
 * Number of seconds to show the notification of the
 * tour button
 */
export const SECONDS_TO_SHOW_NOTIFICATION = 30;
/**
 * Local storage key to save what types of tours the
 * user has seen
 */
export const TOUR_POINT_STORAGE_KEY = 'app_tour';
