/**
 * APIM_SERVICE contains the base routes of the apim services.
 */
export enum APIM_SERVICE {
  AUTH = 'auth',
  CASH_OFFICE = 'cashoffice',
  CONTENT_MANAGER = 'contentmanager',
  CUSTOMER_ACCOUNTS = 'customeraccounts',
  EMPLOYEES = 'employees',
  INVENTORY = 'inventory',
  JOURNALS_CORE = 'journalscore',
  NOTIFICATIONS_CORE = 'notificationscore',
  POWER_BI = 'powerbi',
  PRICES = 'prices',
  PRODUCTS = 'products',
  PROMOTIONS = 'promotions',
  REPORTS = 'reports',
  TELERIK_REPORTS = 'telerikreports',
  WETSTOCK = 'wetstock',
  WHOLESALERS = 'wholesalers',
  SYSTEMCONFIGURATION = 'systemconfiguration',
  SETUPCBEPAY = 'cbepay',
}
