import loadable from '@loadable/component';
import { IRoute } from 'app/routes';
import { loadableConfig } from 'app/routes/loadableConfig';
import { ModuleMap } from 'app/constants';

import DashboardIcon from '@mui/icons-material/Dashboard';

export const DashboardRoutes: IRoute = {
  id: 'Dashboard',
  guid: ModuleMap.Dashboard.View,

  path: '/Dashboard',
  Component: loadable(() => import('./index'), loadableConfig),

  icon: <DashboardIcon />,
  sidebarPath: '/Dashboard',
};
