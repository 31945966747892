import { useMemo, useState } from 'react';

import AppInsightsProvider from 'app/features/Analytics/AppInsightsProvider';
import AuthenticationContext from './AuthenticationContext';
import { IAuthenticationContext, IAccount, AuthenticationError } from './types';
import type MSALAuthentication from 'app/libs/@azure/MSALAuthentication';

const AuthenticationProvider = ({
  client,
  children,
}: {
  client: MSALAuthentication;
  children: React.ReactNode;
}): JSX.Element => {
  const [activeAccount, setActiveAccount] = useState<{
    account: IAccount | null;
    error: AuthenticationError | null;
  }>({
    account: null,
    error: null,
  });

  const [savedAccounts, setSavedAccounts] = useState<IAccount[]>(
    client.getAllAccounts()
  );

  const Memoed = useMemo<IAuthenticationContext>(() => {
    function refreshSavedAccounts() {
      setSavedAccounts(client.getAllAccounts());
    }

    async function signIn() {
      const loginResponse = await client.login();

      refreshSavedAccounts();

      if (!loginResponse || typeof loginResponse === 'string') {
        setActiveAccount({
          account: null,
          error: loginResponse || AuthenticationError.common,
        });
      } else {
        setActiveAccount({
          account: loginResponse,
          error: null,
        });

        return loginResponse;
      }
    }

    async function signOut() {
      await client.logout();

      setActiveAccount({
        account: null,
        error: null,
      });

      refreshSavedAccounts();
    }

    function selectAccount(account: IAccount) {
      const accountResponse = client.selectAccount(account);

      if (typeof accountResponse === 'string') {
        setActiveAccount({
          account: null,
          error: accountResponse,
        });
      } else {
        setActiveAccount({
          account: client.getAccount(),
          error: null,
        });
      }

      refreshSavedAccounts();
    }

    async function removeAccount(account: IAccount) {
      await client.removeAccount(account);

      refreshSavedAccounts();
    }

    async function getToken() {
      const token = await client.getToken();
      if (token) {
        return token;
      }

      setActiveAccount({
        account: null,
        error: null,
      });

      refreshSavedAccounts();
      return null;
    }

    return {
      isAuthenticated: Boolean(activeAccount.account),

      account: activeAccount.account,
      error: activeAccount.error,
      signIn,
      signOut,

      savedAccounts,
      selectAccount,
      removeAccount,

      getToken,
    };
  }, [client, activeAccount, savedAccounts]);

  return (
    <AuthenticationContext.Provider value={Memoed}>
      <AppInsightsProvider account={activeAccount.account}>
        {children}
      </AppInsightsProvider>
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
