import type { ThemeVariant, ValueOf } from '@cbe/ui';

export enum AuthorisationError {
  common = 'common',
  modules = 'modules',
  roles = 'roles',
  stores = 'stores',
  unauthenticated = 'unauthenticated',
  unauthorised = 'unauthorised',
}

export interface IOrganisationData {
  id: string;
  name: string;
  cultureInfoName: string;
  defaultSiteId: string | null;
  logo: null;

  address: string;
  apim: string | null;
  contact: string;
  email: string | null;
  maxSites: number | null;
  maxUsers: number | null;
  phone: string | null;

  powerBIWorkspaceID: string | null;
  subscriptionRenewaDate: string | null;

  /** Tell whether a user should be able to invite external users or add internal users in the sites settings page. */
  hasExternallyManagedUsers: boolean;
  isEnabled: boolean;
  /** If set true a user should be able to see the static data in Promotions item selection. */
  isExtraHierarchyVisible: boolean | null;
  isManagedAzureAD: boolean;

  calendarWeekRule: number | null;
  firstDayOfTheWeek: number | null;
}

export interface ISiteDevice {
  id: string;
  storeId: string;
  organisationId: string;
  tags: {
    storeId: string;
    organisationId: string;
    tillId?: string;
    deviceName?: string;
    deviceType: 'NA' | 'POS' | 'BO' | 'SCO';
  };
  isConnected: boolean;
  timestamp: string;
}

export interface ISiteData {
  id: string;
  name: string;
  azureGroupKey: string;
  cultureInfoName: string;
  landingPage: string;
  logo: null;
  organisation: IOrganisationData;
  organisationId: string;
  theme: ValueOf<typeof ThemeVariant> | string;

  address: string;
  areaManager: string;
  contact: string;
  email: string;
  forecourtBranding: string;
  latitude: number;
  location: string;
  longitude: number;
  phone: string;
  region: string;
  rep: string;
  shopBranding: string;
  squareFootage: number;
  subscriptionRenewaDate: string | null;

  autoFuelLicence: string | null;
  vatLicence: string | null;
  devices: ISiteDevice[] | null;
  dipTimeoutInSeconds: number | null;
  markedFuelLicence: string | null;
  priceTimeoutInSeconds: number | null;

  is24Hour: boolean | null;
  isBunkeringEnabled: boolean | null;
  isBunkeringIncluded: boolean | null;
  isDistributor: null;
  isEnabled: boolean;
  isForecourtRetailer: boolean;
  isWarehouseKeeper: null;
}

/**
 * Initial user data returned from the auth service.
 */
export interface IRawUserData {
  claims: null;
  permissions: unknown[];
  privileges: string[] | null;
  roles: string[];
  stores: ISiteData[];
  termsContractIsApproved: boolean;
  userId: string;
  userName: string;
}

export interface IUserData {
  modules: string[];
  roles: string[];
  stores: ISiteData[];
  privileges: string[];
}

export interface IAppSettings {
  locale: string;
  theme: ValueOf<typeof ThemeVariant> | string;
  /** `landingPage` is a route the app will fallback to on initial login or on page not found home page click. */
  landingPage: string | null;
  features: {
    sites: {
      /** User has any sites with a forecourt */
      haveForecourt: boolean;
    };
  };
}

export interface IGetUser {
  user: IUserData;
  appSettings: IAppSettings;
  _rawUserData: IRawUserData;
}

export interface IAuthorisationContext {
  loadUserData: () => Promise<IGetUser | AuthorisationError | false>;

  user: IUserData | null;
  appSettings: IAppSettings | null;
  _rawUserData: IRawUserData | null;

  /**
   * Previews is a feature flag allowing us to develop features while keeping
   * them from being released.
   */
  SHOW_PREVIEWS: boolean;

  /**
   * The getSite function will return the store when given a stores id, the
   * function will look in the user.claims.store array for the site and return
   * it or if not found will return with an default object structure.
   *
   * @example
   * getSite('00777') => {
   *   id: '00777',
   *   name: 'SuperValu Test',
   *   ...etc.
   * }
   */
  getSite: (siteId: string) => ISiteData;

  /**
   * The `getFirstInstanceOf` function returns the first truthy value
   * of the users stores array which means it wont return values that
   * might be valid. If looking for a specific item from the stores, you
   * should index it directly in you own code.
   *
   * ```md
   * What is truthy?
   * In JavaScript, a truthy value is a value that is considered true when encountered in a Boolean context. All values are truthy unless they are defined as falsy. That is, all values are truthy except false, 0, -0, 0n, "", null, undefined, and NaN.
   * ```
   * @tutorial https://developer.mozilla.org/en-US/docs/Glossary/Truthy
   *
   * @example
   * getFirstInstanceOf('cultureInfoName') => "en-IE"
   */
  getFirstInstanceOf: <Key extends keyof ISiteData>(
    item: Key
  ) => ISiteData[Key] | null;

  /**
   * The `checkUserHasModuleName` function checks the id of the module
   * against the list of modules in the users data to confirm if the
   * user has the module.
   *
   * @example
   * checkUserHasModuleName('Wetstock') => true
   */
  checkUserHasModuleName: (moduleName: string) => boolean;

  /**
   * The `checkUserHasPrivilege` function checks the guid id of the privilege
   * against the users privileges to confirm if the should have access.
   *
   * @example
   * checkUserHasPrivilege('00000000-0000-0000-0000-000000000000') => true
   */
  checkUserHasPrivilege: (privilegeGUID: string) => boolean;

  /**
   * Check if the user has access to a specific privilege, display a notification
   * if not.
   *
   * Alternatively you can pass a callback function to be executed if the user
   * has access.
   *
   * @example
   * ```tsx
   * hasPrivilege(ModuleMap.Wetstock.Dips.Modify) => boolean;
   * hasPrivilege(ModuleMap.Wetstock.Dips.Modify, callbackfn) => boolean;
   * ```
   */
  hasPrivilege: (privilegeGUID: string, callback?: () => void) => boolean;

  /**
   * The `checkUserHasRole` function allows you to check if the user
   * has any of the given roles, if so it returns the list of
   * roles the user has or false if none.
   *
   * @example
   * checkUserHasRole(["Admin", "CBEAdmin"]) => ["Admin"]
   */
  checkUserHasRole: (roles: string[]) => string[] | false;

  /**
   * `checkIfValidDeviceType` handles a check for if the given
   * devices type is valid and already in our list of devices
   * types.
   *
   * @example
   * checkIfValidDeviceType('POS') => 'POS'
   * checkIfValidDeviceType('APPLE') => 'NA'
   */
  checkIfValidDeviceType: (type: string) => ISiteDevice['tags']['deviceType'];
}

/**
 * Common request base from the `Auth` service common model.
 */
export interface ICoreBaseRequest<SortField = string> {
  /**
   * Offset of the results to return.
   */
  offSet?: number;

  /**
   * Maximum number of records to return.
   */
  maxResults?: number;

  /**
   * The column to sort by.
   */
  sortField?: SortField;

  /**
   * Order of the results.
   */
  sortOrder?: 'DESC' | 'ASC';

  /**
   * List of the stores to filter the results by.
   */
  stores?: Partial<ISiteData>[];

  /**
   * If paging is enabled, the total number of records will be
   * returned in the data for use in pagination.
   * @example
   * ```ts
   * {
   *  count: string;
   *  data: unknown[];
   * }
   * ```
   */
  pagination?: boolean;
}
