import { ThemeVariant } from '@cbe/ui';
import { routes } from 'app/routes';
import type { IUserData, IRawUserData, IAppSettings } from '../types';

function formatPrivileges(enabledPrivileges: IRawUserData['privileges']) {
  if (Array.isArray(enabledPrivileges)) {
    // We need to hold onto the top level module names that are enabled to ensure the reports can be filtered.
    const enabledModules = [];

    for (const route of routes) {
      // `########` isn't a known privilege, so this should never match.
      // Required to ensure routes with no GUID are filtered out.

      if (enabledPrivileges.includes(route.guid || '########')) {
        enabledModules.push(route.id);
      }
    }

    return [enabledPrivileges, enabledModules];
  }

  return [null, null];
}

export function formatUserData(user: IRawUserData): IUserData {
  const [privileges, modules] = formatPrivileges(user.privileges);

  return {
    modules: modules || [],
    privileges: privileges || [],
    roles: user.roles,
    stores: user.stores,
  };
}

export function formatAppSettings(user: IUserData): IAppSettings {
  let locale: string | null = null;
  let theme: string | null = null;
  let landingPage: string | null = null;

  let haveForecourt = false;

  for (const store of user.stores) {
    if (!locale && store.organisation.cultureInfoName) {
      locale = store.organisation.cultureInfoName;
    }
    if (!theme && store.theme) {
      theme = store.theme;
    }
    if (!landingPage && store.landingPage) {
      const routeFromId = routes.find(
        (route) => route.id === store.landingPage
      );

      if (routeFromId?.guid && user.privileges.includes(routeFromId.guid)) {
        landingPage = routeFromId.path;
      }
    }

    if (store.isForecourtRetailer) {
      haveForecourt = true;
    }
  }

  // In case the user doesn't have access to a landing page defined in
  // the stores, we want to find the first page they have access to.
  if (!landingPage && user.privileges.length) {
    const firstPage = routes.find((route) => {
      return (
        route?.guid &&
        (route?.sidebarPath || route?.redirectTo) &&
        user.privileges.includes(route.guid)
      );
    });

    if (firstPage?.sidebarPath || firstPage?.redirectTo) {
      landingPage = firstPage?.sidebarPath || firstPage?.redirectTo || null;
    }
  }

  return {
    locale: locale || 'en-IE',
    theme: theme || ThemeVariant.dark,
    landingPage: landingPage || null,

    features: {
      sites: {
        haveForecourt: haveForecourt,
      },
    },
  };
}
