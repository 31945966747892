import { flushSync } from 'react-dom';
import { useMemo } from 'react';
import { useTour } from '@reactour/tour';

import type { IModuleTour, IStepsData } from './types';
import useModuleTourProvider from './useModuleTourProvider';

const useModuleTour = (): IModuleTour => {
  const { currentStep, isOpen, setCurrentStep, setIsOpen, setSteps } =
    useTour();

  const {
    handleOpenTour,
    handleStepsData,
    setDisableKeyboardNavigation,
    tourSettings,
  } = useModuleTourProvider();

  const Memoed = useMemo(() => {
    function openTour(type: IModuleTour['tourType']) {
      flushSync(() => {
        handleOpenTour(type);

        if (type === 'module') {
          setSteps(tourSettings.moduleSteps.allSteps);
        } else if (type === 'whats-new') {
          setSteps(tourSettings.moduleSteps.whatsNew);
        }

        setCurrentStep(0);
      });

      setIsOpen(true);
    }

    function setStepsData(allSteps: IStepsData[], whatsNew: IStepsData[]) {
      // If the steps are being cleared, reset the tours to a closed state.
      if (allSteps.length === 0) {
        setIsOpen(false);
        setCurrentStep(0);
        setSteps([]);
      }

      handleStepsData(allSteps, whatsNew);
    }

    function getCurrentStep() {
      if (tourSettings.type === 'whats-new') {
        return tourSettings.moduleSteps.whatsNew[currentStep];
      } else if (tourSettings.type === 'module') {
        return tourSettings.moduleSteps.allSteps[currentStep];
      }
    }

    return {
      isOpen: isOpen as boolean,
      handleOpenTour: openTour,
      tourType: tourSettings.type,
      steps: {
        whatsNew: tourSettings.moduleSteps.whatsNew,
        allSteps: tourSettings.moduleSteps.allSteps,
      },

      handleStepsData: setStepsData,
      getCurrentStep,

      setDisableKeyboardNavigation,
      shouldShowTour: {
        whatsNew: tourSettings.moduleSteps.whatsNew.length > 0,
        module: tourSettings.moduleSteps.allSteps.length > 0,
      },
    };
  }, [
    currentStep,
    isOpen,
    setCurrentStep,
    setIsOpen,
    setSteps,

    handleOpenTour,
    handleStepsData,
    setDisableKeyboardNavigation,
    tourSettings.moduleSteps.allSteps,
    tourSettings.moduleSteps.whatsNew,
    tourSettings.type,
  ]);

  return Memoed;
};

export default useModuleTour;
