import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { APP_INSIGHTS_KEY } from 'app/constants';

/**
 * {@link https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin React Plugin Documentation}
 */
export const reactPlugin = new ReactPlugin();

/**
 * {@link https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript Application Insights Documentation}
 */
export const appInsights = new ApplicationInsights({
  config: {
    accountId: '',
    autoTrackPageVisitTime: true,
    disableCookiesUsage: true,
    disableTelemetry: false,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    extensions: [reactPlugin],
    instrumentationKey: APP_INSIGHTS_KEY,
    loggingLevelConsole: 2,
    loggingLevelTelemetry: 2,
    maxBatchInterval: 5000,
  },
});

export function setUserContext(accountId: string, username: string): void {
  appInsights.setAuthenticatedUserContext(username, accountId);
  appInsights.context.user.id = accountId;
}

export function clearUserContext(): void {
  appInsights.context.user.id = '';
  appInsights.clearAuthenticatedUserContext();
}
