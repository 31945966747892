import { Component } from 'react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import type { IAccount } from '../Authentication/types';
import {
  appInsights,
  reactPlugin,
  setUserContext,
  clearUserContext,
} from './AppInsightsService';
import { APP_INSIGHTS_KEY, ENABLE_APP_INSIGHTS } from 'app/constants';

interface IAppInsightsProps {
  account: IAccount | null;
  children: React.ReactNode;
}
interface IAppInsightsState {
  initialized: boolean;
}

/**
 * This Component provides telemetry with Azure App Insights
 *
 * {@link https://github.com/microsoft/applicationinsights-react-js/tree/main/sample/applicationinsights-react-sample Sample React App}
 */
class AppInsightsProvider extends Component<
  IAppInsightsProps,
  IAppInsightsState
> {
  constructor(props: IAppInsightsProps) {
    super(props);

    this.state = {
      initialized: false,
    };
  }

  componentDidMount(): void {
    if (
      ENABLE_APP_INSIGHTS &&
      APP_INSIGHTS_KEY &&
      this.state.initialized === false
    ) {
      appInsights.loadAppInsights();

      this.setState({
        initialized: true,
      });

      console.info(`[${new Date().toISOString()}] App Insights Initialised`);
    }
  }

  componentDidUpdate(): void {
    if (this.state.initialized) {
      if (this.props?.account?.username) {
        setUserContext(this.props.account.id, this.props.account.username);
      } else {
        clearUserContext();
      }
    }
  }

  render(): React.ReactNode {
    if (this.state.initialized) {
      return (
        <AppInsightsContext.Provider value={reactPlugin}>
          {this.props.children}
        </AppInsightsContext.Provider>
      );
    }

    return this.props.children;
  }
}

export default AppInsightsProvider;
