import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Popover,
  Paper,
} from '@mui/material';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

import { useAuthentication } from 'app/features/Authentication';
import { useAuthorisation } from 'app/features/Authorisation';
import { useReleaseNotes } from 'app/features/ReleaseNotes/ReleaseNotesProvider';
import { ThemeVariant, useColourMode, useTheme, ValueOf } from '@cbe/ui';

export const HeaderPowerButton = (): JSX.Element => {
  const { t } = useTranslation(['common']);
  const { account, signOut } = useAuthentication();
  const { appSettings } = useAuthorisation();
  const { openReleaseNotes } = useReleaseNotes();

  const { setActiveTheme } = useTheme();
  const { colourMode, toggleColourMode } = useColourMode(account?.id || '');

  useEffect(() => {
    setActiveTheme(
      colourMode === 'light'
        ? (appSettings?.theme as ValueOf<typeof ThemeVariant>) || 'dark'
        : 'night'
    );
  }, [colourMode, setActiveTheme, appSettings?.theme]);

  const buttonRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  function handleOpenMenu() {
    setOpenMenu(true);
  }
  function handleCloseMenu() {
    setOpenMenu(false);
  }

  return (
    <React.Fragment>
      <IconButton
        ref={buttonRef}
        id="power-menu-button"
        onClick={handleOpenMenu}
        aria-label={t('common:header.power-menu.button')}
        aria-describedby={openMenu ? 'power-menu' : undefined}
        color="inherit"
        size="large"
      >
        <PowerSettingsNewIcon />
      </IconButton>

      {openMenu ? (
        <Popover
          anchorEl={buttonRef.current}
          id="power-menu"
          open={openMenu}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Paper id="power-menu-container">
            <List dense>
              <ListItem onClick={openReleaseNotes} button>
                <ListItemIcon>
                  <NewReleasesIcon />
                </ListItemIcon>

                <ListItemText
                  primary={t('common:header.power-menu.view-release-notes')}
                />
              </ListItem>

              <ListItem onClick={toggleColourMode} button>
                <ListItemIcon>
                  {colourMode === 'dark' ? (
                    <Brightness7Icon />
                  ) : (
                    <Brightness5Icon />
                  )}
                </ListItemIcon>

                <ListItemText
                  primary={
                    colourMode === 'dark'
                      ? t('common:header.power-menu.theme-toggle_light')
                      : t('common:header.power-menu.theme-toggle_dark')
                  }
                />
              </ListItem>

              <ListItem onClick={signOut} button>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>

                <ListItemText
                  primary={t('common:header.power-menu.sign-out')}
                />
              </ListItem>
            </List>
          </Paper>
        </Popover>
      ) : null}
    </React.Fragment>
  );
};
