import { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

import { useLocale } from '../Localisation';
import AuthorisationContext from './AuthorisationContext';
import type {
  IAuthorisationContext,
  ISiteData,
  IGetUser,
  ISiteDevice,
} from './types';
import { useUserData } from './getUserData/useUserData';
import { DEFAULT_SITE_DATA } from './getUserData/DEFAULT_DATA';
import { MissingFeatureMessage } from './components/MissingFeatureMessage';
import { UserRole } from 'app/constants';

const AuthorisationProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const getUser = useUserData();
  const setLocale = useLocale((store) => store.setLocale);

  // Users data
  const [userData, setUserData] = useState<IGetUser | null>(null);

  const Memoed = useMemo<IAuthorisationContext>(() => {
    async function loadUserData() {
      const user = await getUser();

      if (typeof user === 'string') {
        return user;
      } else if (user) {
        setLocale(user.appSettings.locale);
        setUserData(user);

        return user;
      }

      return false;
    }

    function getSite(siteId: string): ISiteData {
      const foundSite = userData?.user.stores.find(
        (site) => site.id === siteId
      );

      if (foundSite) {
        return foundSite;
      }

      return DEFAULT_SITE_DATA;
    }

    function getFirstInstanceOf<Key extends keyof ISiteData>(
      item: Key
    ): ISiteData[Key] | null {
      const foundItem = userData?.user.stores.find((site) => site[item]);

      if (foundItem) {
        return foundItem[item];
      }

      return null;
    }

    function checkUserHasModuleName(moduleName: string): boolean {
      const foundModule = userData?.user.modules.find(
        (module) => module === moduleName
      );

      return Boolean(foundModule);
    }

    function checkUserHasPrivilege(privilegeGUID: string): boolean {
      const foundPrivilege = userData?.user.privileges.find(
        (privilege) => privilege === privilegeGUID
      );

      return Boolean(foundPrivilege);
    }

    function hasPrivilege(
      privilegeGUID: string,
      callback?: () => void
    ): boolean {
      const privileged = checkUserHasPrivilege(privilegeGUID);

      if (!privileged) {
        enqueueSnackbar(<MissingFeatureMessage />);
      }

      if (privileged && callback) {
        callback();
      }

      return privileged;
    }

    function checkUserHasRole(roles: string[]): string[] | false {
      const foundRoles = userData?.user.roles.filter((role) =>
        roles.includes(role)
      );

      if (Array.isArray(foundRoles) && foundRoles.length > 0) {
        return foundRoles;
      }
      return false;
    }

    function checkIfValidDeviceType(
      type: string
    ): ISiteDevice['tags']['deviceType'] {
      switch (type) {
        case 'POS':
        case 'BO':
        case 'SCO':
          return type;
        default:
          return 'NA';
      }
    }

    return {
      loadUserData,

      user: userData?.user || null,
      appSettings: userData?.appSettings || null,
      _rawUserData: userData?._rawUserData || null,

      SHOW_PREVIEWS:
        userData?.user.roles.includes(UserRole.CBEPreview) || false,

      getSite,
      getFirstInstanceOf,
      checkUserHasModuleName,
      checkUserHasPrivilege,
      hasPrivilege,
      checkUserHasRole,
      checkIfValidDeviceType,
    };
  }, [userData, getUser, setLocale, enqueueSnackbar]);

  return (
    <AuthorisationContext.Provider value={Memoed}>
      {children}
    </AuthorisationContext.Provider>
  );
};

export default AuthorisationProvider;
